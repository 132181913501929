import { withStyles } from '@mui/styles';
import React from 'react';
import axios from 'axios';
import Table from '@mui/material/Table';
import TableBody from '@mui/material/TableBody';
import TableCell from '@mui/material/TableCell';
import TableContainer from '@mui/material/TableContainer';
import TableHead from '@mui/material/TableHead';
import TableRow from '@mui/material/TableRow';
import Paper from '@mui/material/Paper';
import CircularProgress from '@mui/material/CircularProgress';
import Grid from '@mui/material/Grid';
import Button from '@mui/material/Button';
import EditIcon from '@mui/icons-material/Edit';
import DeleteIcon from '@mui/icons-material/Delete';
import IconButton from '@mui/material/IconButton';
import TextField from '@mui/material/TextField';
import Autocomplete from '@mui/material/Autocomplete';
import { Divider } from '@mui/material';


const useStyles = (theme) => ({
    root: {

    },
    table: {
        minWidth: 450,
    },
});


class Customers extends React.Component {

    constructor(props) {
        super(props);
        this.timeout = 0;
        this.user = JSON.parse(localStorage.getItem("user"));
        this.state = {
            customers: [],
            loading: false,
            editOrAdd: false,
            formData: {
                id: 0,
                name: "",
                phone: "",
                country: "",
                city: "",
                zipcode: "",
                address: "",
                latitude: 0.0,
                longitude: 0.0,
                go_responsible_station: "",
                go_customer_id: ""
            },
            error: {
                name: false,
                country: false,
                zipcode: false,
                city: false,
                address: false,
                go_responsible_station: false,
                go_customer_id: false
            },
            addressSearch: "",
            addressResults: [],
            addressRequestCompleted: true
        };

    }
    componentDidMount() {
        this.getCustomers();
    }

    getCustomers() {
        this.setState({
            loading: true,
        })
        axios.get(`${process.env.REACT_APP_API_URL}/customers`, {
            auth: {
                username: localStorage.getItem('username') || "",
                password: localStorage.getItem('password') || ""
            }
        }).then((response) => {
            //console.log("response.data.result", response.data.result);
            this.setState({
                customers: response.data.result,
                loading: false
            })
        });
    }

    handleOnChangeAddress = (e, newinput) => {
        let { addressRequestCompleted } = this.state;
        //console.log(addressRequestCompleted, newinput);
        if (newinput && newinput.length > 0 && addressRequestCompleted) {

            if (this.timeout) clearTimeout(this.timeout);
            this.timeout = setTimeout(() => {
                //search function
                console.log("serarch now");
                this.setState({
                    addressRequestCompleted: false,
                    //loading: true,
                    addressResults: []
                })
                this.getAddressCompletion(newinput);
            }, 1000);
        }
    }

    handleOnAddressSelected = (e, newinput) => {
        const { addressResults } = this.state;
        let index = e.target;
        //console.log(e.target); 
        if (addressResults && addressResults.length > 0) {
            let { formData } = this.state;
            formData.address = addressResults[0].street + " " + addressResults[0].housenumber;
            formData.country = addressResults[0].country;
            formData.city = addressResults[0].city;
            formData.zipcode = addressResults[0].zipcode;
            formData.latitude = addressResults[0].latitude;
            formData.longitude = addressResults[0].longitude;
            this.setState({
                //addressResults: response.data.result,
                formData: formData
            });
        }
    }

    handleOnAddOrEditClick = (e, customer) => {
        //console.log("e.target", e.target, customer);
        let { formData } = this.state;
        if (customer) {
            formData.id = customer.id;
            formData.name = customer.name;
            formData.phone = customer.phone;
            formData.country = customer.country;
            formData.zipcode = customer.zipcode;
            formData.city = customer.city;
            formData.address = customer.address;
            formData.go_customer_id = customer.go_customer_id;
            formData.go_responsible_station = customer.go_responsible_station;
        }
        else {
            formData.id = 0;
            formData.name = "";
            formData.phone = "";
            formData.zipcode = "";
            formData.city = "";
            formData.address = "";
        }

        this.setState({
            editOrAdd: true,
            formData: formData
        })
    }

    handleOnDeleteClick = (e, customer) => {
        //console.log("user", user);
        //this.updateUser("delete", user);
        customer.deleted = 1;
        this.publishCustomer(customer, "PUT");
        setTimeout(() => {
            this.getCustomers()
        }, 500);
    }

    handleOnChange = (e) => {
        //console.log("e.target", e.target);
        let { formData } = this.state;
        formData[e.target.id] = e.target.value || "";
        this.setState({
            formData: formData
        });
    }

    handleOnBackClick = (e) => {
        this.setState({
            editOrAdd: false
        })
    }

    handleOnSaveClick = (e) => {
        let anyError = false;
        let { formData, error } = this.state;
        //console.log("formData", formData);
        //check name        
        if (formData.name.length == 0) {
            error.name = true;
        }
        else {
            error.name = false;
        }

        //check zipcode        
        if (formData.zipcode.length == 0) {
            error.zipcode = true;
        }
        else {
            error.zipcode = false;
        }

        //check city        
        if (formData.city.length == 0) {
            error.city = true;
        }
        else {
            error.city = false;
        }

        //check address        
        if (formData.address.length == 0) {
            error.address = true;
        }
        else {
            error.address = false;
        }

        for (let key in error) {
            if (error[key] === true) {
                anyError = true
                break;
            }
        }

        if (!anyError) {
            //console.log("upload data", formData);
            this.setState({
                error: error
            })
            this.publishCustomer(formData, (formData.id && formData.id > 0 ? "PUT" : "POST"));
        }
        else {
            this.setState({
                error: error
            })
        }
    }

    getAddressCompletion(search) {
        axios.get(`${process.env.REACT_APP_API_URL}/addresscompletion/` + search, {
            auth: {
                username: localStorage.getItem('username') || "",
                password: localStorage.getItem('password') || ""
            }
        }).then((response) => {
            console.log("response.data.result", response.data.result);
            this.setState({
                addressResults: response.data.result,
                addressRequestCompleted: true,
                loading: false
            })
        });
    }


    publishCustomer(customer, method) {
        this.setState({
            loading: true
        })
        axios({
            method: method,
            url: `${process.env.REACT_APP_API_URL}/customer`,
            data: customer,
            auth: {
                username: localStorage.getItem('username') || "",
                password: localStorage.getItem('password') || ""
            }
        })
            .then((response) => {
                //console.log(response.data);
                this.setState({
                    loading: false,
                    editOrAdd: false,
                })
                this.getCustomers();
            })
            .catch(function (error) {
                console.log(error);
                this.setState({
                    loading: false,
                    showerror: true
                })
            });
    }

    render() {
        const { classes } = this.props;
        const { customers, loading, editOrAdd, formData, error, addressResults, addressSearch } = this.state;
        let content;
        let loadingIndicator = "";
        if (loading) {
            loadingIndicator = <CircularProgress />;
        }

        if (editOrAdd) {
            content = <Grid container spacing={3}>
                <Grid item xs={6}>
                    <TextField
                        type="text"
                        id="name"
                        name="name"
                        label="Kunden Name"
                        onChange={this.handleOnChange}
                        value={formData.name}
                        error={error.name}
                        fullWidth
                        required
                        sx={{ mb: 2 }}
                    />
                </Grid>
                <Grid item xs={6}>
                    <TextField
                        type="text"
                        id="phone"
                        name="phone"
                        label="Kunden Telefon"
                        onChange={this.handleOnChange}
                        value={formData.phone}
                        fullWidth
                        sx={{ mb: 2 }}
                    />
                </Grid>
                <Grid item xs={12}>
                    <Autocomplete
                        id="address_search"
                        freeSolo
                        fullWidth
                        sx={{ mb: 2 }}
                        required
                        autoComplete
                        includeInputInList
                        filterSelectedOption
                        value={addressSearch}
                        onInputChange={this.handleOnChangeAddress}
                        onChange={this.handleOnAddressSelected}
                        filterOptions={(x) => x}
                        options={addressResults.map((option) => option.pattern)}
                        disableClearable
                        renderInput={(params) => (
                            <TextField
                                {...params}
                                label="Adress-Suche"
                                InputProps={{
                                    ...params.InputProps,
                                    type: 'search',
                                }}
                            />
                        )}
                    />
                </Grid>
                <Grid item xs={12}>
                    <TextField
                        type="text"
                        id="address"
                        name="address"
                        label="Straße"
                        onChange={this.handleOnChange}
                        value={formData.address}
                        error={error.address}
                        fullWidth
                        required
                        sx={{ mb: 2 }}
                    />
                </Grid>
                <Grid item xs={3}>
                    <TextField
                        type="text"
                        id="country"
                        name="country"
                        label="LKZ"
                        onChange={this.handleOnChange}
                        value={formData.country}
                        error={error.country}
                        fullWidth
                        required
                        sx={{ mb: 2 }}
                    />
                </Grid>
                <Grid item xs={3}>
                    <TextField
                        type="text"
                        id="zipcode"
                        name="zipcode"
                        label="PLZ"
                        onChange={this.handleOnChange}
                        value={formData.zipcode}
                        error={error.zipcode}
                        fullWidth
                        required
                        sx={{ mb: 2 }}
                    />
                </Grid>
                <Grid item xs={6}>
                    <TextField
                        type="text"
                        id="city"
                        name="city"
                        label="Ort"
                        onChange={this.handleOnChange}
                        value={formData.city}
                        error={error.city}
                        fullWidth
                        required
                        sx={{ mb: 2 }}
                    />
                </Grid>
                <Grid item xs={6}>
                    <TextField
                        type="text"
                        id="go_responsible_station"
                        name="go_responsible_station"
                        label="GO! Station"
                        onChange={this.handleOnChange}
                        value={formData.go_responsible_station}
                        error={error.go_responsible_station}
                        inputProps={{ maxLength: 3, style: { textTransform: "uppercase" } }}
                        fullWidth
                        required
                        sx={{ mb: 2 }}
                    />
                </Grid>
                <Grid item xs={6}>
                    <TextField
                        type="text"
                        id="go_customer_id"
                        name="go_customer_id"
                        label="GO! Kundennummer"
                        onChange={this.handleOnChange}
                        value={formData.go_customer_id}
                        error={error.go_customer_id}
                        inputProps={{ maxLength: 7 }}
                        fullWidth
                        required
                        sx={{ mb: 2 }}
                    />
                </Grid>
                <Grid item xs={2}>
                </Grid>
                <Grid item xs={4}>
                    <Button variant="contained" color="secondary" onClick={this.handleOnBackClick}>Back</Button>
                </Grid>
                <Grid item xs={2}>
                </Grid>
                <Grid item xs={4}>
                    <Button variant="contained" color="primary" onClick={this.handleOnSaveClick}>Save</Button>
                </Grid>
            </Grid>
        }
        else {

            if (customers && customers.length) {
                content =
                    <Grid container spacing={3}>
                        <Grid item xs={10}>
                            <TableContainer component={Paper}>
                                <Table stickyHeader className={classes.table} aria-label="ArticleList">
                                    <TableHead>
                                        <TableRow>
                                            <TableCell>ID</TableCell>
                                            <TableCell >Name</TableCell>
                                            <TableCell >Address</TableCell>
                                            <TableCell >Edit</TableCell>
                                        </TableRow>
                                    </TableHead>
                                    <TableBody>
                                        {customers.map((row) => (
                                            <TableRow key={row.id}>
                                                <TableCell component="th" scope="row">
                                                    {row.id}
                                                </TableCell>
                                                <TableCell >{row.name}</TableCell>
                                                <TableCell >{row.address}<br />{row.country} {row.zipcode} {row.city}</TableCell>
                                                <TableCell>
                                                    <IconButton edge="end" aria-label="delete" onClick={((e) => this.handleOnDeleteClick(e, row))} >
                                                        <DeleteIcon />
                                                    </IconButton>
                                                    &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;
                                                    <IconButton edge="end" aria-label="edit" onClick={((e) => this.handleOnAddOrEditClick(e, row))} >
                                                        <EditIcon />
                                                    </IconButton>
                                                </TableCell>
                                            </TableRow>
                                        ))}
                                    </TableBody>
                                </Table>
                            </TableContainer>
                        </Grid>
                        <Grid item xs={2}>
                            <Button variant="contained" onClick={this.handleOnAddOrEditClick} disabled={this.user.role == "Supervisor" ? true : false} >Add Customer</Button>
                        </Grid>
                    </Grid>
            }
        }

        return (
            <div className={classes.root}>
                {loadingIndicator}
                {content}
            </div>
        );
    }
}

export default withStyles(useStyles)(Customers)