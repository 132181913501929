import { withStyles } from '@mui/styles';
import React from 'react';
import axios from 'axios';

import Button from '@mui/material/Button';
import CssBaseline from '@mui/material/CssBaseline';
import TextField from '@mui/material/TextField';
import Box from '@mui/material/Box';
import Typography from '@mui/material/Typography';
import Container from '@mui/material/Container';
import Grid from '@mui/material/Grid';
import Alert from '@mui/material/Alert';
import CircularProgress from '@mui/material//CircularProgress';



const useStyles = (theme) => ({
    paper: {
      marginTop: theme.spacing(8),
      display: 'flex',
      flexDirection: 'column',
      alignItems: 'center',
    },
    avatar: {
      margin: theme.spacing(1),
      backgroundColor: theme.palette.secondary.main,
    },
    form: {
      width: '100%', // Fix IE 11 issue.
      marginTop: theme.spacing(1),
    },
    submit: {
      margin: theme.spacing(3, 0, 2),
    },
});

class Login extends React.Component {

    constructor(props) {
        super(props);
        this.state = {
            username: "",
            password: "",
            password2: "",
            passwordReset: false,
            error: {
              username: false,
              password: false,
              password2: false,              
            },
        };
    }

    componentDidUpdate(){
      const {pwReset, username} = this.props;
      //console.log(this.props);
      const {passwordReset} = this.state;
      if(pwReset  && !passwordReset){
        this.setState({
          passwordReset: true,
          username: username
        })
      }
      
    }

    handleUserName = (e) => {        
        this.setState({
            username: e.target.value
        })
        return;
    }

    handlePassword = (e) => {        
        this.setState({
            password: e.target.value
        })
        return;
    }

    handlePassword2 = (e) => {        
      this.setState({
          password2: e.target.value
      })
      return;
  }

    handleLoginOrPwReset = (e) => {
        //console.log(e);
        const {handleLogin} = this.props;
        
        const {username, password, error, passwordReset, password2} = this.state;
        if( passwordReset ){
          let anyError = false;
          if( !username || username.length == 0 ){
            return alert("please type a username")
          }

          //check username        
          if(username.length == 0 || username.indexOf("@") <= -1){            
            error.username = true;
          }
          else {            
            error.username = false;
          }
          //check password
          if(password.length < 8){            
            error.password = true;
          }
          else {            
            error.password = false;
          }

          if( password != password2 ){
            error.password2 = true;
          }
          else {
            error.password2 = false;
          }

          for(let key in error){
            if(error[key] === true){
                anyError = true
                break;
            }
          }

          if( !anyError ){
            let user = {
              username: username,
              password: password,
              initial_password: "",
            }
            axios({
              method: "PUT",
              url: `${process.env.REACT_APP_API_URL}/resetpassword`,
              data: user,              
            })
            .then( (response) => {
              //console.log("response.data.status", response.data);
              if( response.data.status == 200 ){
                setTimeout(() => {                      
                  return handleLogin(username, password)
                }, 500);                
              }
                
            })
            .catch(function (error) {
                console.log(error);
            });
          }
          else {
            this.setState({
              error: error
            })
          }

        }
        else {
          return handleLogin(username, password)
        }
    }

    handlePasswordReset = (e) => {
      let { passwordReset } = this.state;
      
      this.setState({
        passwordReset: !passwordReset,
        password: ""
      })
    }

    render(){
        const { classes, loading } = this.props;
        const { passwordReset, username, error, password } = this.state;
        let title = "Sign in";
        let passwordResetButton = "Reset Password";
        if(passwordReset){
          title = "Reset Password"
          passwordResetButton = "Back";
        }

        let loadingIndicator = "";
        if(loading){
            loadingIndicator = <CircularProgress />;
        }
        
        return (
            <Container component="main" maxWidth="xs">
              <CssBaseline />
              <div className={classes.paper}>
                
                <Typography component="h1" variant="h5">
                  {title}
                </Typography>
                
                    <form className={classes.form} noValidate>
                    <Grid container spacing={3}>
                    { passwordReset == true 
                      ?
                      <Grid item xs={12}>
                        <Alert severity="info">When you log in for the first time you must set a new password!</Alert>
                      </Grid>
                      : 
                      ""
                    }
                      <Grid item xs={12}>
                        <TextField
                          variant="outlined"
                          margin="normal"
                          required
                          fullWidth
                          id="email"
                          label="User"
                          name="user"
                          onChange={this.handleUserName}
                          value={username}
                          error={error.username}
                          autoFocus
                        />
                        <TextField
                          variant="outlined"
                          margin="normal"
                          required
                          fullWidth
                          name="password"
                          label="Password"
                          type="password"
                          id="password"
                          value={password}
                          onChange={this.handlePassword}
                          error={error.password}
                        /> 
                        { passwordReset == true 
                          ? 
                          <TextField
                            variant="outlined"
                            margin="normal"
                            required
                            fullWidth
                            name="password"
                            label="Retype Password"
                            type="password"
                            id="password2"
                            onChange={this.handlePassword2}
                            error={error.password2}
                          />
                          : 
                          ""
                        }
                      </Grid>
                    </Grid>
                    <Grid container spacing={3}>
                      <Grid item xs={6}>
                        <Button
                          type="button"
                          fullWidth
                          variant="contained"                        
                          color="secondary"
                          className={classes.submit}
                          onClick={this.handlePasswordReset}
                        >
                          {passwordResetButton}
                        </Button> 
                      </Grid>  
                      <Grid item xs={6}>
                        <Button
                          type="button"
                          fullWidth
                          variant="contained"
                          color="primary"
                          className={classes.submit}
                          onClick={this.handleLoginOrPwReset}
                        >
                          {title}
                        </Button> 
                      </Grid>    
                    </Grid>
                  </form>
                
                  {loadingIndicator}
                
              </div>
              <Box mt={8}>
                
              </Box>
          </Container>
        );
    }
}

export default withStyles(useStyles)(Login)