import { withStyles } from '@mui/styles';
import React from 'react';
import { Link } from '@mui/material';
import Divider from '@mui/material/Divider';

const useStyles = (theme) => ({
  root: {
    
  },
  table: {
    minWidth: 650,
  },
});


class Upload extends React.Component {

    constructor(props) {
        super(props);
        this.state = {
            
        };
        
    }

    render(){
        const { classes } = this.props;
        const { message, openAlert, uploadStatus } = this.state;

        return (
            <div className={classes.root}>  
                <h3>External Links</h3>
                <Divider />
                <p >
                <Link href="https://www.general-overnight.com/deu_de/unternehmen/stationen/giessen/go-team-giessen.html" target="_blank" >Impressum</Link>
                <br /> <br /> 
                <Link href="https://www.general-overnight.com/deu_de/datenschutz.html" target="_blank" >Datenschutz</Link>
                </p>
            </div>
        );
    }
}

export default withStyles(useStyles)(Upload)