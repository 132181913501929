import { withStyles } from '@mui/styles';
import React from 'react';
import CircularProgress from '@mui/material//CircularProgress';
import Login from '../Login/Login';
import Private from '../Private/Private';
import axios from 'axios';
import Snackbar from '@mui/material/Snackbar';
import MuiAlert from '@mui/material/Alert';
import Tools from '../Tools';
import { Margin } from '@mui/icons-material';


const useStyles = theme => ({
  root: {
    flexGrow: 1,
    height: "100%",
  },
  containerWrapper: {
      height: "100%",
  },
  paper: {
    padding: theme.spacing(2),
    textAlign: 'center',
    color: theme.palette.text.secondary,
  },
});

function Alert(props) {
    return <MuiAlert elevation={6} variant="filled" {...props} />;
}

class Layout extends React.Component {

    constructor(props) {
        super(props);
        this.state = {
            login: false,
            loading: false,
            openAlert: false,
            username: "",
            message: "",
            loginStatus: ""
        };
    }

    componentDidMount(){
        const pw = localStorage.getItem('password') || "";
        const user = localStorage.getItem('username') || "";
        if(user && pw && user.length > 0 && pw.length > 0){
            this.doAuth(user, pw);
        }
    }

    handleClose = (event, reason) => {
        if (reason === 'clickaway') {
          return;
        }
    
        this.setState({
            openAlert: false
        })
    };

    handleLogin = (username, password) => {
        this.doAuth(username, password);
    }

    handleLogout = (e) => {
        this.setState({            
            loading: false,
            login: false,
            pwReset: false
        });
        localStorage.removeItem('username');
        localStorage.removeItem('password');
        localStorage.removeItem('user');
    }

    doAuth(username, password){
        //console.log("doAuth", username);
        this.setState({            
            loading: true,
        })
        axios.get(`${process.env.REACT_APP_API_URL}/auth`, {
            auth: {
                username: username,
                password: password
            }
        }).then((response) => {            
            //console.log("response", response.data);
            if(response.data.status == 200){
                localStorage.setItem('username', username);
                if( password.length != 32 ){
                    localStorage.setItem('password', Tools.md5(password));
                }
                localStorage.setItem('user', JSON.stringify(response.data.result));
                //console.log("resetPassword", response.data.result)
                if( response.data.result.resetPassword){
                    this.setState({            
                        loading: false,
                        login: false,
                        resetPassword: true,
                        username: response.data.result.username
                    });
                }
                else {
                    this.setState({            
                        loading: false,
                        login: true,
                        resetPassword: false
                    });
                }
            }
            else {
                this.setState({            
                    loading: false,
                    login: false,
                    openAlert: true,
                    message: "login failed, wrong credentials",
                    loginStatus: "error"
                });
            }
            
        }).catch((err) => {
            this.setState({            
                loading: false,
                login: false,
                openAlert: true,
                message: "Login failed, wrong Credentials.",
                loginStatus: "error"
            });
        })
    }

    render(){
        const {login, resetPassword, username, loading, openAlert, loginStatus, message} = this.state;
        //console.log("resetPassword", resetPassword, "login", login);
        

        let page;
        if( !login ){            
            page = <Login handleLogin={this.handleLogin} pwReset={resetPassword} username={username} loading={loading} />            
        }
        
        else {
            page = <Private handleLogout={this.handleLogout} />
        }
        
        return (
           <div>           
            {page}
            <Snackbar open={openAlert} autoHideDuration={6000} onClose={this.handleClose}>
                <div>
                    <Alert onClose={this.handleClose} severity={loginStatus}>
                        {message}
                    </Alert>
                </div>
            </Snackbar>
           </div>
        );
    }
}

export default withStyles(useStyles)(Layout)

