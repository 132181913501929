import { withStyles } from '@mui/styles';
import React from 'react';
import axios from 'axios';
import clsx from 'clsx';

import List from '@mui/material/List';
import IconButton from '@mui/material/IconButton';
import ListItem from '@mui/material/ListItem';
import ListItemText from '@mui/material/ListItemText';


import ListItemSecondaryAction from '@mui/material/ListItemSecondaryAction';

import Button from '@mui/material/Button';
import DeleteIcon from '@mui/icons-material/Delete';
import EditIcon from '@mui/icons-material/Edit';
import Grid from '@mui/material/Grid';
import TextField from '@mui/material/TextField';
import CircularProgress from '@mui/material/CircularProgress';
import Switch from '@mui/material/Switch';

import InputLabel from '@mui/material/InputLabel';
import MenuItem from '@mui/material/MenuItem';
import FormControl from '@mui/material/FormControl';
import Select from '@mui/material/Select';


const useStyles = (theme) => ({
  root: {
    
  },
  textField: {
    width: '100ch',
  },
  stationForm: {
    color: "white",
    "border-color": "white",
    width: '100ch'
  },
});


class Users extends React.Component {

    constructor(props) {
        super(props);
        this.user = JSON.parse(localStorage.getItem("user"));  
        this.state = {
            users: [],
            editOrAdd: false,
            user: {
                id: null,
                firstname: "",
                lastname: "",
                username: "",
                initial_password: "",
                role: "",
                stationId: 0,
                customerId: 0
            },
            error: {
                username: false,
                initial_password: false,
                role: false,
                stationCustomer: false
            },
            stations: [],
            customers: [],
            sationId: 0,
            currentUser: JSON.parse(localStorage.getItem("user")),
            roles: ["Station", "Customer"],
            loading: false
        };
        
    }
    componentDidMount(){
        this.getUser();

        if( this.state.currentUser.role == "Admin"  ){
            const { roles } = this.state;
            roles.push("Admin");
            roles.push("Supervisor");
            this.setState({
                stationId: this.state.currentUser.stationId,
                roles: roles
            })
            this.getStations();
        }
        else {
            this.setState({
                stationId: this.state.currentUser.stationId                
            })
        }
        
    }

    handleOnAddClick = (e) => {                
        this.setState({
            editOrAdd: true,
            user: {
                id: null
            }
        })
    }

    handleOnEditClick = (e, user) => {  
        //console.log("user", user);
        this.setState({
            editOrAdd: true,
            user: user
        })
    }

    handleOnDeleteClick = (e, user) => {  
        //console.log("user", user);
        this.updateUser("delete", user);
    }

    handleOnBackClick = (e) => {
        this.setState({
            editOrAdd: false,
            user: {
                id: null
            }
        })
    }

    handleOnSaveClick = (e) => {
        const {user, error} = this.state;
        let anyError = false;
        //console.log("user", user);
        this.setState({
            loading: true
        })
        let method="post";
        if( user.id != null ){
            method = "put";
        }

        //validation
        //check username        
        if(user.username.length == 0 || user.username.indexOf("@") <= -1){            
            error.username = true;
        }
        else {            
            error.username = false;
        }

        //check password
        if(user.initial_password.length < 8){            
            error.initial_password = true;
        }
        else {            
            error.initial_password = false;
        }

        //check rolw
        if(user.role.length == 0){
            error.role = true;
        }
        else {
            error.role = false;
        }

        //check station or customer
        if(user.stationId == 0 && user.customerId == 0){
            error.stationCustomer = true;
        }
        else {
            error.stationCustomer = false;
        }

        for(let key in error){
            if(error[key] === true){
                anyError = true
                break;
            }
        }

        if( !anyError ){
            this.updateUser(method, user);
        }
        else {
            this.setState({
                loading: false,
                error: error
            })
        }
    }

    handleOnUserValue = (e) => {
        let {user} = this.state;
        user[e.target.id] = e.target.value || "";
        this.setState({
            user: user
        });        
    }

    handleOnActive = (e) => {
        let {user} = this.state;
        user.active = e.target.checked;
        this.setState({
            user: user
        });        
    }

    getUser(){
        axios.get(`${process.env.REACT_APP_API_URL}/users`, {
            auth: {
                username: localStorage.getItem('username') || "",
                password: localStorage.getItem('password') || ""
            }
        }).then((response) => {            
            this.setState({
                users: response.data.result
            })
        });
    }

    getStations(){
        axios.get(`${process.env.REACT_APP_API_URL}/stations`, {
            auth: {
                username: localStorage.getItem('username') || "",
                password: localStorage.getItem('password') || ""
            }
        }).then((response) => {            
            this.setState({
                stations: response.data.result
            })
        });
    }

    getCustomers(){
        axios.get(`${process.env.REACT_APP_API_URL}/customers`, {
            auth: {
                username: localStorage.getItem('username') || "",
                password: localStorage.getItem('password') || ""
            }
        }).then((response) => {            
            this.setState({
                customers: response.data.result
            })
        });
    }

    updateUser(method, user){
        
        if(! user.stationId || user.stationId == 0 ){
            user.stationId = this.state.currentUser.stationId;
        }
        
        axios({
            method: method,
            url: `${process.env.REACT_APP_API_URL}/user`,
            data: user,
            auth: {
                username: localStorage.getItem('username') || "",
                password: localStorage.getItem('password') || ""
            }
        })
        .then( (response) => {
            //console.log(response);
            setTimeout(() => {
                this.getUser();
                this.setState({
                    editOrAdd: false,
                    loading: false,
                    user: {
                        id: null
                    }
                });
            }, 500);
       })
        .catch(function (error) {
            console.log(error);
        });
    }

    handleSwitchStation = (e) => {
        const {user} = this.state;
        user.stationId = e.target.value;
        this.setState({
            user: user
        })                
    }

    handleSwitchCustomer = (e) => {
        const {user} = this.state;
        user.customerId = e.target.value;
        this.setState({
            user: user
        })                
    }

    handleSwitchRole = (e) => {
        const {user} = this.state;
        user.role = e.target.value;        
        this.setState({
            user: user
        });
        if(user.role == "Customer"){
            this.getCustomers()
        }
    }


    render(){
        const { classes } = this.props;
        const { users, editOrAdd, user, error, loading, stations, customers, roles } = this.state;
        
        let listItems = [];
        if(users){
            for(let i =0; i < users.length; i++){
                let tag;
                let secondary;
                if( users[i].active == 0 ){
                    tag = <s>{users[i].username + ",   " + (users[i].acronym ? users[i].acronym : users[i].name)}</s>;
                }
                else {
                    tag = users[i].username + ",   " + (users[i].acronym ? "Station: " + users[i].acronym : " " + (users[i].name ? + "Kunde: " + users[i].name : ""));
                    secondary = `name: ${(users[i].firstname ? users[i].firstname : "") + " " + (users[i].lastname ? users[i].lastname : "")},   role: ${users[i].role}`;
                }
                listItems.push(
                    <ListItem key={users[i].id}>                        
                        <ListItemText
                            primary={tag}
                            secondary={secondary}    
                        />
                        <ListItemSecondaryAction>
                            <IconButton edge="end" aria-label="delete" disabled={this.user.role != "Admin" ? true : false} onClick={((e) => this.handleOnDeleteClick(e, users[i]))} >
                                <DeleteIcon />
                            </IconButton>
                            &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;
                            <IconButton edge="end" aria-label="edit" onClick={((e) => this.handleOnEditClick(e, users[i]))} >
                                <EditIcon />
                            </IconButton>
                        </ListItemSecondaryAction>
                    </ListItem>
                )
            }
        }

        let content;
        let loadingIndicator = "";
        if(loading){
            loadingIndicator = <CircularProgress />;
        }

        if(!editOrAdd){
            content = 
            <div>
                <Grid container spacing={3}>
                    <Grid item xs={10}>
                        <List>{listItems}</List>
                    </Grid>
                    <Grid item xs={2}>
                        <Button variant="contained" onClick={this.handleOnAddClick} disabled={this.user.role == "Supervisor" ? true : false} >Add User</Button>
                    </Grid>
                </Grid>
                
            </div>;
        }
        else {

            let rolesSelect = "";
            if(roles.length > 0){
                let rows = [];
                for(let i =0; i < roles.length; i++){
                    rows.push(
                        <MenuItem key={roles[i]} value={roles[i]}>{roles[i]}</MenuItem>
                    )
                }
                rolesSelect = 
                    <Grid item xs={12}>
                        <FormControl className={classes.stationForm}>
                            <InputLabel>Role*</InputLabel>
                            <Select value={user.role} required={true} label="Role" onChange={this.handleSwitchRole} error={error.role} > 
                                {rows}
                            </Select>
                        </FormControl>
                    </Grid>;
            }

            let stationSelect = "";
            if( stations.length > 0 ){
                let rows = [];
                for(let i =0; i < stations.length; i++){
                    rows.push(
                        <MenuItem value={stations[i].id}>{stations[i].name}</MenuItem>
                    )
                }
                stationSelect = 
                    <Grid item xs={12}>
                        <FormControl className={classes.stationForm}>
                            <InputLabel>Station*</InputLabel>
                            <Select value={user.stationId} required={true} label="Station" onChange={this.handleSwitchStation} error={error.stationCustomer}> 
                                {rows}
                            </Select>
                        </FormControl>
                    </Grid>;
            }

            let customerSelect = "";
            //console.log("role", user.role);
            if( customers.length > 0 && user.role == "Customer"){
                stationSelect = "";
                let rows = [];
                for(let i =0; i < customers.length; i++){
                    rows.push(
                        <MenuItem value={customers[i].id}>{customers[i].name}</MenuItem>
                    )
                }
                customerSelect = 
                    <Grid item xs={12}>
                        <FormControl className={classes.stationForm}>
                            <InputLabel>Customer*</InputLabel>
                            <Select value={user.customerId} required={true} label="Customer" onChange={this.handleSwitchCustomer} error={error.stationCustomer}> 
                                {rows}
                            </Select>
                        </FormControl>
                    </Grid>;
            }


            content = 
                <form className={classes.root} noValidate autoComplete="off">
                    <Grid container spacing={3}>
                        <Grid item xs={12}>
                            <TextField 
                                id="username" 
                                label="E-Mail"
                                required={true}
                                className={clsx(classes.textField)} 
                                onChange={this.handleOnUserValue} 
                                value={user.username}
                                error={error.username}
                                />
                        </Grid>
                        <Grid item xs={12}>
                            <TextField 
                                id="initial_password" 
                                label="Initial-Passwort, 8 signs" 
                                type="password"
                                required={true}
                                className={clsx(classes.textField)} 
                                onChange={this.handleOnUserValue} 
                                value={user.initial_password}
                                error={error.initial_password}
                                />
                        </Grid>
                        <Grid item xs={12}>
                            <TextField 
                                id="firstname" 
                                label="Firstname" 
                                className={clsx(classes.textField)}  
                                onChange={this.handleOnUserValue} 
                                value={user.firstname}
                                />
                        </Grid>
                        <Grid item xs={12}>
                            <TextField 
                                id="lastname" 
                                label="Lastname" 
                                className={clsx(classes.textField)} 
                                onChange={this.handleOnUserValue} 
                                value={user.lastname}
                                />
                        </Grid>
                        {rolesSelect}
                        {stationSelect}
                        {customerSelect}
                        <Grid item xs={6}>Active</Grid>
                        <Grid item xs={6}>
                            <Switch
                                edge="end"
                                onChange={this.handleOnActive}                      
                                checked={user.active}
                                inputProps={{ 'aria-labelledby': 'switch-list-label-bluetooth' }}
                            />
                        </Grid>
                        
                        <Grid item xs={1}>
                        </Grid>
                        <Grid item xs={5}>
                            <Button variant="contained" color="secondary" onClick={this.handleOnBackClick}>Back</Button>
                        </Grid>
                        <Grid item xs={1}>
                        </Grid>
                        <Grid item xs={5}>
                            <Button variant="contained" onClick={this.handleOnSaveClick}>Save</Button>
                        </Grid>
                    </Grid>
                </form>
        }

        return (
            <div className={classes.root}>  
                {loadingIndicator}
                {content}                              
            </div>
        );
    }
}

export default withStyles(useStyles)(Users)