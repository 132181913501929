import { withStyles } from '@mui/styles';
import React from 'react';
import axios from 'axios';
import Table from '@mui/material/Table';
import TableBody from '@mui/material/TableBody';
import TableCell from '@mui/material/TableCell';
import TableContainer from '@mui/material/TableContainer';
import TableHead from '@mui/material/TableHead';
import TableRow from '@mui/material/TableRow';
import Paper from '@mui/material/Paper';
import Grid from '@mui/material/Grid';
import Button from '@mui/material/Button';
import CircularProgress from '@mui/material/CircularProgress';
import { Checkbox } from '@mui/material';
import IconButton from '@mui/material/IconButton';
import EditIcon from '@mui/icons-material/Edit';
import DeleteIcon from '@mui/icons-material/Delete';
import TextField from '@mui/material/TextField';

const useStyles = (theme) => ({
    root: {

    },
    table: {
        minWidth: 450,
    },
});

class Articles extends React.Component {

    constructor(props) {
        super(props);
        this.user = JSON.parse(localStorage.getItem("user"));
        this.state = {
            articles: [],
            loading: false,
            editOrAdd: false,
            formData: {
                id: 0,
                label: "",
                weight: 0,
                unit: "",
                serialNumberMandatory: false
            },
            station: {
                id: 0,
                label: "",
                weight: 0,
                unit: "",
                serialNumberMandatory: false
            },
            error: {
                label: false,
                weight: false,
                unit: false
            }
        };

    }
    componentDidMount() {
        this.getArticle();
    }

    getArticle() {
        this.setState({
            loading: true,
        })
        axios.get(`${process.env.REACT_APP_API_URL}/articles.distinct2`, {
            auth: {
                username: localStorage.getItem('username') || "",
                password: localStorage.getItem('password') || ""
            }
        }).then((response) => {
            //console.log("response.data.result", response.data.result);
            this.setState({
                articles: response.data.result,
                loading: false
            })
        });
    }

    handleOnAddOrEditClick = (e, article) => {
        //console.log("e.target", e.target, article);
        let { formData } = this.state;
        if (article) {
            formData.id = article.id;
            formData.label = article.label;
            formData.weight = article.weight;
            formData.unit = article.unit;
            formData.serialNumberMandatory = article.serialNumberMandatory;
        }
        else {
            formData.label = "";
            formData.weight = "";
            formData.unit = "KG";
            formData.serialNumberMandatory = false;
        }

        this.setState({
            editOrAdd: true,
            formData: formData
        })
    }

    handleOnChange = (e) => {
        //console.log("e.target", e.target);
        let { formData } = this.state;
        formData[e.target.id] = e.target.value || "";
        this.setState({
            formData: formData
        });
    }

    handleOnBackClick = (e) => {
        this.setState({
            editOrAdd: false
        })
    }

    handleOnSaveClick = (e) => {
        let anyError = false;
        let { formData, error } = this.state;
        //console.log("formData", formData);
        //check label        
        if (formData.label.length == 0) {
            error.label = true;
        }
        else {
            error.label = false;
        }

        //check weight        
        if (formData.weight.length == 0) {
            error.weight = true;
        }
        else {
            error.weight = false;
        }

        //check unit        
        if (formData.unit.length == 0) {
            error.unit = true;
        }
        else {
            error.unit = false;
        }

        //check serialNumberMandatory        
        if (formData.serialNumberMandatory.length == 0) {
            error.serialNumberMandatory = true;
        }
        else {
            error.serialNumberMandatory = false;
        }

        for (let key in error) {
            if (error[key] === true) {
                anyError = true
                break;
            }
        }

        if (!anyError) {
            //console.log("upload data", formData);
            this.setState({
                error: error
            })
            this.publishArticle(formData, (formData.id && formData.id > 0 ? "PUT" : "POST"));
        }
        else {
            this.setState({
                error: error
            })
        }
    }

    publishArticle(article, method) {
        this.setState({
            loading: true
        })
        axios({
            method: method,
            url: `${process.env.REACT_APP_API_URL}/article`,
            data: article,
            auth: {
                username: localStorage.getItem('username') || "",
                password: localStorage.getItem('password') || ""
            }
        })
            .then((response) => {
                //console.log(response.data);
                this.setState({
                    loading: false,
                    editOrAdd: false,
                })
                this.getArticle();
            })
            .catch(function (error) {
                console.log(error);
                this.setState({
                    loading: false,
                    showerror: true
                })
            });
    }

    render() {
        const { classes } = this.props;
        const { articles, editOrAdd, article, loading, error, formData } = this.state;
        let content;
        let loadingIndicator = "";
        if (loading) {
            loadingIndicator = <CircularProgress />;
        }

        if (editOrAdd) {
            content = <Grid container spacing={3}>
                <Grid item xs={2}>
                    <TextField
                        type="text"
                        id="id"
                        name="id"
                        label="ID"
                        onChange={this.handleOnChange}
                        value={formData.id != 0 ? formData.id : "NEU"}
                        error={error.id}
                        fullWidth
                        disabled
                        inputProps={{ readOnly: true }}
                        sx={{ mb: 2 }}
                    />
                </Grid>
                <Grid item xs={10}>
                    <TextField
                        type="text"
                        id="label"
                        name="label"
                        label="Artikelname"
                        onChange={this.handleOnChange}
                        value={formData.label}
                        error={error.label}
                        fullWidth
                        required
                        sx={{ mb: 2 }}
                    />
                </Grid>
                <Grid item xs={2}>
                    <TextField
                        type="number"
                        id="weight"
                        name="weight"
                        label="Gewicht"
                        mask="0.0"
                        defaultValue={1}
                        onChange={this.handleOnChange}
                        value={formData.weight}
                        fullWidth
                        InputProps={{ inputProps: { min: 0.1, max: 99.9, step: ".1" } }}
                        sx={{ mb: 2 }}
                    />
                </Grid>
                <Grid item xs={2}>
                    <TextField
                        type="text"
                        id="unit"
                        name="unit"
                        label="Einheit"
                        defaultValue={"KG"}
                        onChange={this.handleOnChange}
                        value={formData.unit}
                        fullWidth
                        disabled
                        inputProps={{ readOnly: true }}
                        sx={{ mb: 2 }}
                    />
                </Grid>
                <Grid item xs={2}>
                    <TextField
                        type="number"
                        id="serialNumberMandatory"
                        name="serialNumberMandatory"
                        label="S/N mandatory"
                        defaultValue={0}
                        onChange={this.handleOnChange}
                        value={formData.serialNumberMandatory}
                        InputProps={{ inputProps: { min: 0, max: 1 } }}
                        fullWidth
                        sx={{ mb: 2 }}
                    />
                </Grid>
                <Grid item xs={4}>
                </Grid>
                <Grid item xs={2}>
                </Grid>
                <Grid item xs={4}>
                    <Button variant="contained" color="secondary" onClick={this.handleOnBackClick}>Back</Button>
                </Grid>
                <Grid item xs={2}>
                </Grid>
                <Grid item xs={4}>
                    <Button variant="contained" color="primary" onClick={this.handleOnSaveClick}>Save</Button>
                </Grid>
            </Grid>
        }
        else {

            if (articles && articles.length) {
                content =
                    <Grid container spacing={3}>
                        <Grid item xs={10}>
                            <TableContainer component={Paper}>
                                <Table stickyHeader className={classes.table} aria-label="ArticleList">
                                    <TableHead>
                                        <TableRow>
                                            <TableCell>Label</TableCell>
                                            <TableCell>ID</TableCell>
                                            <TableCell>Weight</TableCell>
                                            <TableCell>S/N mandatory</TableCell>
                                            <TableCell>Edit</TableCell>
                                        </TableRow>
                                    </TableHead>
                                    <TableBody>
                                        {articles.map((row) => (
                                            <TableRow key={row.id}>
                                                <TableCell component="th" scope="row">{row.label}</TableCell>
                                                <TableCell >{row.id}</TableCell>
                                                <TableCell >{row.weight} {row.unit}</TableCell>
                                                <TableCell >
                                                    <Checkbox
                                                        value={row.serialNumberMandatory}
                                                        checked={row.serialNumberMandatory === 1 ? true : false}>
                                                    </Checkbox>
                                                </TableCell>
                                                <TableCell>
                                                    <IconButton edge="end" aria-label="edit" onClick={((e) => this.handleOnAddOrEditClick(e, row))} >
                                                        <EditIcon />
                                                    </IconButton>
                                                </TableCell>
                                            </TableRow>
                                        ))}
                                    </TableBody>
                                </Table>
                            </TableContainer>
                        </Grid>
                        <Grid item xs={2}>
                            <Button variant="contained" onClick={this.handleOnAddOrEditClick} disabled={this.user.role != "Admin" ? true : false} >Add Article</Button>
                        </Grid>
                    </Grid>
            }
        }

        return (
            <div className={classes.root}>
                {loadingIndicator}
                {content}
            </div>
        );
    }
}

export default withStyles(useStyles)(Articles)