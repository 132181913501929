import { withStyles } from '@mui/styles';
import React from 'react';
import axios from 'axios';
import Table from '@mui/material/Table';
import TableBody from '@mui/material/TableBody';
import TableCell from '@mui/material/TableCell';
import TableContainer from '@mui/material/TableContainer';
import TableHead from '@mui/material/TableHead';
import TableRow from '@mui/material/TableRow';
import TablePagination from '@mui/material/TablePagination';
import Paper from '@mui/material/Paper';
import CircularProgress from '@mui/material/CircularProgress';
import Badge from '@mui/material/Badge';
import { styled } from '@mui/material/styles';
import IconButton from '@mui/material/IconButton';
import ShoppingCartIcon from '@mui/icons-material/ShoppingCart';
import Tooltip from '@mui/material/Tooltip';
import Button from '@mui/material/Button';
import MuiAlert from '@mui/material/Alert';
import AppBar from '@mui/material/AppBar';
import Grid from '@mui/material/Grid';
import Box from '@mui/material/Box';
import ArrowDownIcon from '@mui/icons-material/ArrowDownward';
import ListItemText from '@mui/material/ListItemText';
import ListItem from '@mui/material/ListItem';
import List from '@mui/material/List';
import CloseIcon from '@mui/icons-material/Close';
import Toolbar from '@mui/material/Toolbar';
import Typography from '@mui/material/Typography';
import Divider from '@mui/material/Divider';
import Slide from '@mui/material/Slide';
import Stack from '@mui/material/Stack';

import Dialog from '@mui/material/Dialog';
import DialogActions from '@mui/material/DialogActions';
import DialogContent from '@mui/material/DialogContent';

const Alert = React.forwardRef(function Alert(props, ref) {
    return <MuiAlert elevation={6} ref={ref} variant="filled" {...props} />;
});

const StyledBadge = styled(Badge)(({ theme }) => ({
    '& .MuiBadge-badge': {
        right: -3,
        top: 13,
        border: `2px solid ${theme.palette.background.paper}`,
        padding: '0 4px',
    },
}));

const useStyles = (theme) => ({
    root: {

    },
    table: {
        minWidth: 450,
    },
});

const Transition = React.forwardRef(function Transition(props, ref) {
    return <Slide direction="up" ref={ref} {...props} />;
});

class OrderHistoryCustomer extends React.Component {

    constructor(props) {
        super(props);
        this.state = {
            orderhistory: [],
            totalHistoryItems: 0,
            page: 0,
            limit: 15,
            selectedOrder: {},
            openOrderDetailsDialog: false,
            loading: false
        };

    }

    componentDidMount() {
        const { showDetails } = this.props;
        const {page, limit} = this.state;
        this.getOrderHistory(page, limit);
        if (showDetails) {
            setTimeout(() => {
                const { orderhistory } = this.state;
                const orderItem = orderhistory.filter((item) => item.id == showDetails);
                if (orderItem && orderItem.length > 0) {
                    this.setState({
                        openOrderDetailsDialog: true,
                        selectedOrder: orderItem[0]
                    })
                }

            }, 1500);
        }
    }

    onEditClicked = (e) => {
        const { handleOrderEdit } = this.props;
        const { orderhistory } = this.state;
        e.preventDefault();
        if (e.target.name && e.target.name.length > 0) {
            const id = e.target.name;
            let orderItem = orderhistory.filter((item) => item.id == id);
            handleOrderEdit(orderItem[0]);
        }
    }

    onDetailClicked = (e) => {
        const { orderhistory } = this.state;
        e.preventDefault();
        if (e.target.name && e.target.name.length > 0) {
            const id = e.target.name;
            let orderItem = orderhistory.filter((item) => item.id == id);
            this.setState({
                openOrderDetailsDialog: true,
                selectedOrder: orderItem[0]
            })
        }
    }

    handleDetailsClose = () => {
        this.setState({
            openOrderDetailsDialog: false
        })
    }

    handleChangePage = (event, newPage) => {
        //console.log("handleChangePage", newPage);
        const {limit} = this.state;
        
        this.setState({
            page: newPage
        })
        this.getOrderHistory(newPage, limit);
    }

    handleChangeRowsPerPage = (event) => {
        //console.log("handleChangeRowsPerPage", event.target.value);
        let {page, limit} = this.state;
        limit = event.target.value;
        this.setState({
            limit: limit
        });

        this.getOrderHistory(page, limit);
    };


    getOrderHistory(page, limit) {
        this.setState({
            loading: true,
        })


        const params = {
            page: page,
            limit: limit
        }
        //console.log("params", params);
        axios.get(`${process.env.REACT_APP_API_URL}/orderhistory/customer`, {
            auth: {
                username: localStorage.getItem('username') || "",
                password: localStorage.getItem('password') || ""
            },
            params: params
        }).then((response) => {
            //console.log("response.data.result", response.data.result);
            this.setState({
                orderhistory: response.data.result,
                totalHistoryItems: response.data.total,
                loading: false
            })
        });
    }

    render() {
        const { classes } = this.props;
        const { orderhistory, loading, openOrderDetailsDialog, selectedOrder, totalHistoryItems, page, limit } = this.state;
        let content;
        let loadingIndicator = "";
        if (loading) {
            loadingIndicator = <CircularProgress />;
        }
        //console.log("orderhistory", orderhistory)
        if (orderhistory && orderhistory.length > 0) {
            for (let i = 0; i < orderhistory.length; i++) {
                orderhistory[i].tooltip = "";
                for (let j = 0; j < orderhistory[i].articles.length; j++) {
                    orderhistory[i].tooltip += orderhistory[i].articles[j].count + " x " + orderhistory[i].articles[j].label + ' (' + (orderhistory[i].articles[j].serialnumber ? orderhistory[i].articles[j].serialnumber : "N/A") + ')\n';
                }
                orderhistory[i].tooltip += "";
            }
        }

        if (orderhistory && orderhistory.length) {
            content =
                <div>
                    <TableContainer component={Paper}>
                        <Table stickyHeader className={classes.table} aria-label="Auftragshistorie">
                            <TableHead>
                                <TableRow>
                                    <TableCell>ID</TableCell>
                                    <TableCell >Consignee</TableCell>
                                    <TableCell >User</TableCell>
                                    <TableCell >Reference</TableCell>
                                    <TableCell >Station</TableCell>
                                    <TableCell >Status</TableCell>
                                    <TableCell >Article</TableCell>
                                    <TableCell >Date</TableCell>
                                    <TableCell >Action</TableCell>
                                </TableRow>
                            </TableHead>
                            <TableBody>
                                {orderhistory.map((row) => (
                                    <TableRow key={row.id}>
                                        <TableCell component="th" scope="row">
                                            {row.id}
                                        </TableCell>
                                        <TableCell >{row.recipient_company}<br />{row.recipient_address}<br />{row.recipient_country} {row.recipient_zipcode} {row.recipient_city}</TableCell>
                                        <TableCell >{(row.articles && row.articles.length) ? row.articles[0].user_firstname + " " + row.articles[0].user_lastname : ""}</TableCell>
                                        <TableCell >{(row.customer_reference == "null") ? '-' : row.customer_reference}</TableCell>
                                        <TableCell >{row.acronym}</TableCell>
                                        <TableCell >
                                            {row.label}
                                            {row.shipment_number &&
                                                <div><strong>{(row.shipment_number == "null") ? '-' : row.shipment_number}</strong></div>
                                            }
                                        </TableCell>
                                        <TableCell>
                                            <Tooltip
                                                title={
                                                    <div style={{ whiteSpace: 'pre-line' }}>{row.tooltip}</div>
                                                }
                                            >
                                                <IconButton aria-label="cart">
                                                    <StyledBadge badgeContent={row.articles.length} color="secondary">
                                                        <ShoppingCartIcon />
                                                    </StyledBadge>
                                                </IconButton>
                                            </Tooltip>
                                        </TableCell>
                                        <TableCell >{row.datetime}</TableCell>
                                        <TableCell >
                                            <Stack direction="row" spacing={2}>
                                                <Button variant="contained" name={row.id} onClick={this.onDetailClicked}>
                                                    Details
                                                </Button>
                                            </Stack>
                                        </TableCell>

                                    </TableRow>
                                ))}
                            </TableBody>
                        </Table>
                    </TableContainer>
                    <TablePagination
                                rowsPerPageOptions={[15, 30, 60]}
                                component="div"
                                showFirstButton={true}
                                showLastButton={true}
                                count={totalHistoryItems}
                                rowsPerPage={limit}
                                page={page}
                                onPageChange={this.handleChangePage}
                                onRowsPerPageChange={this.handleChangeRowsPerPage}
                            />

                    {/*info dialog over whole order*/}
                    <Dialog
                        maxWidth={"900px"}
                        open={openOrderDetailsDialog}
                        onClose={this.handleDetailsClose}
                        TransitionComponent={Transition}
                    >
                        <AppBar sx={{ position: 'relative' }} style={{ background: '#940234' }} >
                            <Toolbar>
                                <IconButton
                                    edge="start"
                                    color="inherit"
                                    onClick={this.handleDetailsClose}
                                    aria-label="close"
                                >
                                    <CloseIcon />
                                </IconButton>
                                <Typography sx={{ ml: 2, flex: 1 }} variant="h6" component="div">
                                    Details of Order {selectedOrder.id}
                                </Typography>
                            </Toolbar>
                        </AppBar>
                        <Grid container spacing={1} xs={12}>
                            <Grid item xs={4}>
                                {/*
                                <Box item
                                    display="flex"
                                    alignItems="center"
                                    m={3}
                                    p={1}
                                    sx={{ border: '2px solid lightgrey' }}
                                >
                                    {selectedOrder.customer_name} <br></br>
                                    {selectedOrder.customer_phone} <br></br>
                                    {selectedOrder.customer_address} <br></br>
                                    {selectedOrder.customer_country + " " + selectedOrder.customer_zipcode + " " + selectedOrder.customer_city}
                                </Box>
                                */}

                                <Box item
                                    display="flex"
                                    alignItems="center"
                                    m={3}
                                    p={1}
                                    sx={{ border: '2px dashed lightgrey' }}
                                >
                                    <ArrowDownIcon
                                        fontSize='large'>
                                    </ArrowDownIcon>
                                    <big>Ab Lager <strong>{selectedOrder.acronym}</strong></big>
                                    <ArrowDownIcon
                                        fontSize='large'>
                                    </ArrowDownIcon>
                                </Box>

                                <Box item
                                    display="flex"
                                    //alignItems="center"
                                    m={3}
                                    p={1}
                                    sx={{ border: '2px solid lightgrey' }}
                                >
                                    {selectedOrder.recipient_company} <br></br>
                                    {selectedOrder.recipient_name} <br></br>
                                    {selectedOrder.recipient_phone} <br></br>
                                    {selectedOrder.recipient_address} <br></br>
                                    {selectedOrder.recipient_country + " " + selectedOrder.recipient_zipcode + " " + selectedOrder.recipient_city}
                                </Box>
                            </Grid>

                            <Grid item xs={8}>
                                <Box item
                                    display="flex"
                                    alignItems="center"
                                    m={3}
                                    p={1}
                                    sx={{ border: '0px dashed lightgrey' }}
                                >
                                    <TableContainer component={Paper}>
                                        <Table stickyHeader className={classes.table} aria-label="Article" width={750}>
                                            <TableHead>
                                                <TableRow>
                                                    <TableCell>Name</TableCell>
                                                    <TableCell>Box</TableCell>
                                                    <TableCell>Amount</TableCell>
                                                    <TableCell>Serialnumber</TableCell>
                                                </TableRow>
                                            </TableHead>
                                            <TableBody>
                                                {selectedOrder && selectedOrder.articles ? selectedOrder.articles.map((row) => (
                                                    <TableRow key={row.id}>
                                                        <TableCell component="th" scope="row">{row.label}</TableCell>
                                                        <TableCell >{row.box}</TableCell>
                                                        <TableCell >{row.count}</TableCell>
                                                        <TableCell >{row.serialnumber}</TableCell>
                                                    </TableRow>
                                                )) : ""}
                                            </TableBody>
                                        </Table>
                                    </TableContainer>
                                </Box>

                                <Box item
                                    display="flex"
                                    alignItems="center"
                                    m={3}
                                    p={1}
                                    sx={{ border: '0px dashed lightgrey' }}
                                >
                                    <List>
                                        <ListItem >
                                            <ListItemText primary="Additional Info" secondary={selectedOrder.additional_infos} />
                                        </ListItem>
                                        <ListItem >
                                            <ListItemText primary="Customer Reference" secondary={selectedOrder.customer_reference} />
                                        </ListItem>
                                        <ListItem >
                                            <ListItemText primary="Status" secondary={selectedOrder.label} />
                                        </ListItem>
                                    </List>
                                </Box>
                            </Grid>

                        </Grid>
                    </Dialog>

                </div >

        }
        else {
            content =
                <div>
                    <Alert severity="info">This list is empty!</Alert>
                </div>
        }

        return (
            <div className={classes.root}>
                {loadingIndicator}
                {content}
            </div>
        );
    }
}

export default withStyles(useStyles)(OrderHistoryCustomer)