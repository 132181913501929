import { withStyles } from '@mui/styles';
import React from 'react';
import axios from 'axios';
import {DropzoneArea} from 'material-ui-dropzone'
import Snackbar from '@mui/material/Snackbar';
import MuiAlert from '@mui/material/Alert';

const useStyles = (theme) => ({
  root: {
    
  },
  table: {
    minWidth: 650,
  },
});

function Alert(props) {
    return <MuiAlert elevation={6} variant="filled" {...props} />;
}

class Upload extends React.Component {

    constructor(props) {
        super(props);
        this.state = {
            files: [],
            openAlert: false,
            message: "",
            uploadStatus: ""
        };
        
    }

    handleAlert = () => {

    }

    handleClose = (event, reason) => {
        if (reason === 'clickaway') {
          return;
        }
    
        this.setState({
            openAlert: false
        })
    };
    
    handleChange = (files) => {
        console.log(files);
        this.setState({            
            files: files,
        });

        if( files && files.length > 0 ){
            let formData = new FormData();
            formData.append("file", files[0]);
            axios({
                method: "post",
                url: `${process.env.REACT_APP_API_URL}/upload`,
                data: formData,
                auth: {
                    username: localStorage.getItem('username') || "",
                    password: localStorage.getItem('password') || ""
                },
                headers: {
                    "Content-Type": "multipart/form-data"
                }
            })
            .then( (response) => {
                console.log(response);
                /*
                if(response.data.status == 200){
                    setTimeout( () => {
                        this.setState({
                            openAlert: true,
                            message: "upload succeeded",
                            uploadStatus: "success"
                        });
                    }, 1500)                    
                }
                else {
                    this.setState({
                        openAlert: true,
                        message: "upload failed",
                        uploadStatus: "error"
                    })
                }
                */
            })
            .catch(function (error) {
                console.log(error);
            });
        }
    }

    render(){
        const { classes } = this.props;
        const { message, openAlert, uploadStatus } = this.state;

        return (
            <div className={classes.root}>  
                <Snackbar open={openAlert} autoHideDuration={6000} onClose={this.handleClose}>
                    <Alert onClose={this.handleClose} severity={uploadStatus}>
                        {message}
                    </Alert>
                </Snackbar>
                <DropzoneArea
                    onChange={this.handleChange}
                    onAlert={this.handleAlert}
                />                    
            </div>
        );
    }
}

export default withStyles(useStyles)(Upload)