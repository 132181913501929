import { withStyles } from '@mui/styles';
import React from 'react';
import axios from 'axios';
import { Waypoint } from "react-waypoint";
import Table from '@mui/material/Table';
import TableBody from '@mui/material/TableBody';
import TableCell from '@mui/material/TableCell';
import TableContainer from '@mui/material/TableContainer';
import TableHead from '@mui/material/TableHead';
import TableRow from '@mui/material/TableRow';
import Paper from '@mui/material/Paper';
import CircularProgress from '@mui/material/CircularProgress';
import FormControl from '@mui/material/FormControl';
import Select from '@mui/material/Select';
import { InputLabel } from '@mui/material';
import MenuItem from '@mui/material/MenuItem';
import Button from '@mui/material/Button';
import Grid from '@mui/material/Grid';
import TextField from '@mui/material/TextField';

import Typography from '@mui/material/Typography';

import IconButton from '@mui/material/IconButton';
import EditIcon from '@mui/icons-material/Edit';
import LightbulbIcon from '@mui/icons-material/Lightbulb';
import OutboundIcon from '@mui/icons-material/Outbound';
import FormGroup from '@mui/material/FormGroup';
import FormControlLabel from '@mui/material/FormControlLabel';
import Switch from '@mui/material/Switch';

import Dialog from '@mui/material/Dialog';
import DialogContentText from '@mui/material/DialogContentText';
import DialogActions from '@mui/material/DialogActions';
import DialogContent from '@mui/material/DialogContent';
import Slide from '@mui/material/Slide';
import AppBar from '@mui/material/AppBar';
import Toolbar from '@mui/material/Toolbar';
import CloseIcon from '@mui/icons-material/Close';
import FeedIcon from '@mui/icons-material/Feed';
import Tooltip from '@mui/material/Tooltip';
import Box from '@mui/material/Box';
import Radio from '@mui/material/Radio';
import RadioGroup from '@mui/material/RadioGroup';

import MuiAlert from '@mui/material/Alert';

import moment from 'moment';

const useStyles = (theme) => ({
    table: {
        minWidth: 650,
    },
});

const Transition = React.forwardRef(function Transition(props, ref) {
    return <Slide direction="up" ref={ref} {...props} />;
});

const Alert = React.forwardRef(function Alert(props, ref) {
    return <MuiAlert elevation={6} ref={ref} variant="filled" {...props} />;
});

class StockItems extends React.Component {

    constructor(props) {
        super(props);
        this.user = JSON.parse(localStorage.getItem("user"));
        this.state = {
            stockItems: [],
            filteredStockItemsList: [],
            stations: [],
            articles: [],
            selectedStockItem: {},
            selectedStation: 4,
            page: 0,
            limit: 50,
            selectedArticle: "",
            storageLocation: "",
            addOrEditVars: {
                box: "",
                countList: 0,
                serialnumber: "",
                storage_place: "",
                inboundHWB: "",
                ts_inbound: "",
                outboundHWB: "",
                ts_outbound: ""
            },
            error: {
                station_id: false,
                article_id: false,
                box: false,
                countList: false,
                serialnumber: false,
                storage_place_ismandatory: false,
                outboundHWB: false
            },
            loading: false,
            editOrAdd: false,
            dialogViewInventory: false,
            dialogViewOutbound: false,
            dialogViewDetails: false,
            count: "",
            showAll: false,
            snMandatory: false,
            showInventoryCheckError: false,
            markTableRowIndex: false,
            currentUser: JSON.parse(localStorage.getItem("user"))
        };
                
    }

    componentDidMount() {
        let { currentUser, selectedStation, count, page, limit } = this.state;

        //console.log("selectedStation", selectedStation);
        
        this.getStations();
        if (currentUser && currentUser.role == "Station") {
            this.setState({
                selectedStation: currentUser.stationId
            })
            this.getStockItems(currentUser.stationId, page, limit, {count: count});
        }
        else {
            this.getStockItems(selectedStation, page, limit, {count: count});
        }
        this.getArticle();

        
        this.setState(({
            loading: true
        }))
        
        
    }

    componentDidUpdate() {
        const { selectedStation, selectedArticle, stockItems, selectedStockItem } = this.state;
        if (selectedArticle.length != 0 && selectedStation.length != 0) {
            let filteredStockItem = stockItems.filter((stockItem => stockItem.article_id == selectedArticle));
            if (filteredStockItem && filteredStockItem.length > 0 && (Object.keys(selectedStockItem).length == 0 || selectedStockItem.article_id != filteredStockItem[0].article_id)) {
                this.setState({
                    selectedStockItem: filteredStockItem[0]
                })
            }
        }
    }

    onStockItemsChange = (e) => {
        this.setState({
            selectedStation: e.target.value
        });
    }

    onStationChange = (e) => {
        this.getStations();
        const {count, page, limit} = this.state;

        this.setState({
            selectedStation: e.target.value,
            loading: true
        });
        this.getStockItems(e.target.value, page, limit, {count: count});
        

    }

    onArticleChange = (e) => {
        const articleId = e.target.value;
        const { articles, error } = this.state;
        const article = articles.filter((a) => a.id == articleId)[0];
        console.log("articles", article);

        let snMandatory = false;

        if (article.serialNumberMandatory === 1) {
            snMandatory = true;
        } else {
            snMandatory = false;
        }

        console.log("snMandatory", snMandatory);
        /*
        let newErrors = {};

        for (let key in error) {
            newErrors[key] = error[key];
        }

        if (!article.serialNumberMandatory) {
            delete newErrors["serialnumber"];
        }
        */

        this.setState({
            selectedArticle: articleId,
            snMandatory: snMandatory
        });
    }

    handleOnAddItemClick = (e) => {
        this.setState({
            editOrAdd: true,
            addOrEditVars: {
                box: "",
                countList: 0,
                serialnumber: "",
                inboundHWB: "",
                ts_inbound: null,
                outboundHWB: "",
                ts_outbound: null
            }
        })
    }

    handleOnEditClick = (e, row) => {
        this.setState({
            dialogViewInventory: true,
            selectedStockItem: row
        })
    }

    handleOnOutboundClick = (e, row) => {
        this.setState({
            dialogViewOutbound: true,
            selectedStockItem: row
        })
    }

    handleOnDetailsClick = (e, row) => {
        this.setState({
            dialogViewDetails: true,
            selectedStockItem: row
        })
    }

    handleDetailsClose = () => {
        this.setState({
            dialogViewInventory: false,
            dialogViewOutbound: false,
            dialogViewDetails: false
        })
    }

    handleOnBackClick = (e) => {
        this.setState({
            editOrAdd: false,
            addOrEditVars: {
                box: "",
                countList: 0,
                serialnumber: "",
                inboundHWB: "",
                ts_inbound: null,
                outboundHWB: "",
                ts_outbound: null
            }
        })
    }

    handleOnChange = (e) => {
        const { addOrEditVars } = this.state;
        addOrEditVars[e.target.name] = e.target.value;

        //console.log("addOrEditVars", addOrEditVars)

        this.setState({
            addOrEditVars: addOrEditVars
        })
    }

    handleOnAddClick = (e) => {
        const { selectedArticle, selectedStation, addOrEditVars, error, stations, storageLocation, snMandatory, articles } = this.state;
        const currentStation = stations.filter((station) => station.id == selectedStation)[0];
        const currentArticle = articles.filter((article) => article.id == selectedArticle)[0];

        if (!selectedArticle) {
            error.article_id = true;
        }
        else {
            error.article_id = false;
        }

        if (!selectedStation) {
            error.station_id = true;
        }
        else {
            error.station_id = false;
        }

        if (addOrEditVars.box.length == 0) {
            error.box = true;
        }
        else {
            error.box = false;
        }

        if (addOrEditVars.countList == 0) {
            error.countList = true;
        }
        else {
            error.countList = false;
        }

        if (addOrEditVars.serialnumber.length == 0 && currentArticle.serialNumberMandatory == true) {
            error.serialnumber = true;
        }
        else {
            error.serialnumber = false;
        }

        if (addOrEditVars.inboundHWB.length < 10) {
            error.inboundHWB = true;
        }
        else {
            error.inboundHWB = false;
        }

        if (currentStation && currentStation.id > 0 && currentStation.storage_place_ismandatory && storageLocation.length == 0) {
            error.storage_place_ismandatory = true;
        }
        else {
            error.storage_place_ismandatory = false;
        }

        this.setState({
            error: error
        })

        if (selectedArticle && selectedStation && addOrEditVars.box.length > 0 && addOrEditVars.inboundHWB.length > 0) {
            const stockItemsToTransmit = [];

            /*
            console.log("selectedStation", selectedStation)
            console.log("selectedArticle", selectedArticle)
            console.log("addOrEditVars.box", addOrEditVars.box)
            console.log("addOrEditVars.serialnumber", addOrEditVars.serialnumber)
            console.log("addOrEditVars.inboundHWB", addOrEditVars.inboundHWB)
            */

            let inboundTS = moment().format("yyyy-MM-DD HH:mm:ss");
            //console.log("inboundTS", inboundTS)

            stockItemsToTransmit.push({
                station_id: selectedStation,
                article_id: selectedArticle,
                box: addOrEditVars.box,
                serialnumber: addOrEditVars.serialnumber,
                storage_place: addOrEditVars.storage_place,
                count: 1,
                outsourced_not: addOrEditVars.inboundHWB,
                datetime: inboundTS
            })

            //console.log("stockItemsToTransmit", stockItemsToTransmit);
            this.updateStock(stockItemsToTransmit, selectedStation);
        }
    }

    updateStock(stockItems, selectedStation) {
        axios({
            method: "POST",
            url: `${process.env.REACT_APP_API_URL}/stockitems`,
            data: stockItems,
            auth: {
                username: localStorage.getItem('username') || "",
                password: localStorage.getItem('password') || ""
            }
        })
            .then((response) => {                
                const responsItem = response.data[0];
                const {count, page, limit} = this.state;
                setTimeout(() => {
                    this.setState({
                        editOrAdd: false,
                        loading: false,
                    });
                    this.getStockItems(selectedStation, page, limit, {count: count}, responsItem);
                }, 500);
            })
            .catch(function (error) {
                console.log(error);
            });
    }

    getStations() {
        console.log("getStations");
        axios.get(`${process.env.REACT_APP_API_URL}/stations`, {
            auth: {
                username: localStorage.getItem('username') || "",
                password: localStorage.getItem('password') || ""
            }
        }).then((response) => {
            this.setState({
                stations: response.data.result
            })
        });
    }

    getStockItems(byStation, page, limit, query, updateItem) {
        this.setState({
            loading: true,
            stockItems: []
        })
        let path = "stockitems";
        let params = {
            page: page,
            limit: limit
        };
        if (byStation) {
            path = "stockitems/station/" + byStation;
        }

        if(query){
            for(let key in query){
                params[key] = query[key]
            }            
        }        
        
        axios.get(`${process.env.REACT_APP_API_URL}/${path}`, {
            auth: {
                username: localStorage.getItem('username') || "",
                password: localStorage.getItem('password') || ""
            },
            params: params
        }).then((response) => {
            this.setState({
                stockItems: response.data.result,
                //filteredStockItemsList: response.data.result,
                loading: false,
                markTableRowIndex: updateItem ? true : false
            })
        });
    }

    appendStockItems(byStation, page, limit, query, updateItem) {
        const {stockItems} = this.state;
        let path = "stockitems";
        let params = {
            page: page,
            limit: limit
        };
        if (byStation) {
            path = "stockitems/station/" + byStation;
        }

        if(query){
            for(let key in query){
                params[key] = query[key];
            }
        }        
        
        axios.get(`${process.env.REACT_APP_API_URL}/${path}`, {
            auth: {
                username: localStorage.getItem('username') || "",
                password: localStorage.getItem('password') || ""
            },
            params: params
        }).then((response) => {
            this.setState({
                stockItems: [...stockItems, ...response.data.result],
                //filteredStockItemsList: response.data.result,
                loading: false,
                markTableRowIndex: updateItem ? true : false
            })
        });
    }

    getArticle() {
        this.setState({
            loading: true,
        })
        axios.get(`${process.env.REACT_APP_API_URL}/articles.distinct2`, {
            auth: {
                username: localStorage.getItem('username') || "",
                password: localStorage.getItem('password') || ""
            }
        }).then((response) => {
            //console.log("response.data.result", response.data.result);
            this.setState({
                articles: response.data.result,
                loading: false
            })
        });
    }

    getWarnstufe(datetime_inbound, maxAlter, datetime_outbound) {
        let alter = Math.ceil(moment.duration(moment().diff(datetime_inbound)).asDays());
        let warnstufe = 0;

        let response = {};
        response.color = "";
        response.fontWeight = "";
        response.fontSize = "";

        if (maxAlter === null) {
            maxAlter = 99999;
        }

        if (datetime_outbound === null && maxAlter < 99999) {

            if (alter >= maxAlter) {
                warnstufe = 9;
            } else if (alter >= (maxAlter - 14)) {
                warnstufe = 5;
            } else {
                warnstufe = 0;
            }
            //console.log("warnstufe", warnstufe)

            if (warnstufe == 9) {
                response.color = "error";
                response.fontWeight = "bold";
                response.fontSize = "200%";
            } else if (warnstufe == 5) {
                response.color = "#EADF30";
                response.fontWeight = "bold";
                response.fontSize = "150%";
            } else {
                response.color = "success";
                response.fontWeight = "normal";
                response.fontSize = "100%";
            }
        } else {
            response.color = "success";
            response.fontWeight = "normal";
            response.fontSize = "0%";
        }
        //console.log("response", response)
        return response;
    }

    handleShowAllSwitch = (e) => {
        this.setState({
            showAll: e.target.checked
        })
    }

    handleFilterInOut = (e) => {
        const {selectedStation, page, limit} = this.state;
        this.setState({
            count: e.target.value,
        })
        

        this.getStockItems(selectedStation, page, limit, {count: e.target.value})

    }

    
    

    
    handleOnInventoryCheckClick = (e, id) => {
        this.setState({
            loading: true,
        });
        let selectedStation = this.state.selectedStation;
        if (id && id > 0) {
            const stockItemsToTransmit = [];
            stockItemsToTransmit.push({
                id: id,
                datetime_lastinventorycheck: moment().format("yyyy-MM-DD HH:mm:ss.SSSSSS")
            })
            console.log("stockItemsToTransmit", stockItemsToTransmit);
            this.inventoryStock(stockItemsToTransmit, selectedStation);
        } else {
            console.log("handleOnInventoryCheckClick", "not executed");
        };

        this.setState({
            dialogViewInventory: false
        })
    }

    handleOnOutboundBookClick = (e, id) => {
        this.setState({
            loading: true,
        });
        const { addOrEditVars, error, selectedStation } = this.state;

        if (addOrEditVars.outboundHWB.length <= 10) {
            error.outboundHWB = true;
        } else {
            error.outboundHWB = false;
        }

        //console.log("error", error)

        this.setState({
            error: error
        })

        //console.log("id", id);
        //console.log("addOrEditVars", addOrEditVars);

        if (id && id > 0 && addOrEditVars.outboundHWB.length >= 10) {
            const stockItemsToTransmit = [];
            stockItemsToTransmit.push({
                id: id,
                datetime_outofstock: moment().format("yyyy-MM-DD HH:mm:ss.SSSSSS"),
                count: 0,
                outsourced_ops: addOrEditVars.outboundHWB
            })
            console.log("stockItemsToTransmit", stockItemsToTransmit);
            this.inventoryStock(stockItemsToTransmit, selectedStation);
            this.setState({
                dialogViewOutbound: false
            })
        } else {
            console.log("handleOnOutboundClick", "not executed")
        };
    }

    inventoryStock(stockItemsToTransmit) {
        const {selectedStation, page, limit, count} = this.state;
        axios({
            method: "PUT",
            url: `${process.env.REACT_APP_API_URL}/stockitems`,
            data: stockItemsToTransmit,
            auth: {
                username: localStorage.getItem('username') || "",
                password: localStorage.getItem('password') || ""
            }
        })
        .then((response) => {
            setTimeout(() => {
                this.setState({
                    addOrEditMode: 0,                    
                });
                this.getStockItems(selectedStation, page, limit, {count: count});
            }, 500);
        })
        .catch(function (error) {
            console.log(error);
        });
    }

    handleWaypointEnter = () => {
        let {selectedStation, page, limit, count} = this.state;
        page++;
        this.setState({
            page: page
        })
        this.appendStockItems(selectedStation, page, limit, {count: count});
        
    }

    render() {
        const { classes } = this.props;
        const { showInventoryCheckError, snMandatory, dialogViewInventory, dialogViewOutbound, dialogViewDetails, stockItems, stations, articles, loading, selectedStation, selectedArticle, editOrAdd, selectedStockItem, showAll, error, markTableRowIndex } = this.state;
        
        let content;
        let header;
        let submitInfo;
        let loadingIndicator = "";

        if (loading) {
            loadingIndicator = <CircularProgress />;
        }

        const currentStation = stations.filter((station) => station.id == selectedStation)[0];

        if (showInventoryCheckError) {
            submitInfo = <Alert item xs={3} severity="error">The current item is considered 'out of stock'.<br />PLEASE CHECK IMMEDIATLY!</Alert>;
        }

        
        let findIndex = -1;
        if( markTableRowIndex ){
            for(let i =0; i < stockItems.length; i++){
                if(stockItems[i].id > findIndex){
                    findIndex = stockItems[i].id;
                }
            }
        }

        
        if (editOrAdd) {
            content =
                <div>
                    <Grid container spacing={3}>
                        <h2>Stockitem</h2>
                        <Grid container spacing={3}>
                            <Grid item xs={10}>
                                <Grid container spacing={3}>
                                    <Grid item xs={6}>
                                        <FormControl fullWidth>
                                            <InputLabel id="stationList">Stations *</InputLabel>
                                            <Select
                                                id="stationList"
                                                name="stationList"
                                                value={selectedStation}
                                                label="Station"
                                                onChange={this.onStationChange}
                                                required
                                                error={error.station_id}
                                            >
                                                {stations.map((row) => (
                                                    <MenuItem key={row.id} value={row.id} name={row.acronym}>{row.acronym} - {row.name}</MenuItem>
                                                ))}
                                            </Select>
                                        </FormControl>
                                    </Grid>
                                    <Grid item xs={6}>
                                        <TextField
                                            type="text"
                                            id="inboundHWB"
                                            name="inboundHWB"
                                            label="Inbound HWB"
                                            required
                                            onChange={this.handleOnChange}
                                            error={error.inboundHWB}
                                            inputProps={{ maxLength: 12 }}
                                            fullWidth
                                        />
                                    </Grid>
                                    <Grid item xs={6}>
                                        <FormControl fullWidth>
                                            <InputLabel id="articleList">Article *</InputLabel>
                                            <Select
                                                id="articleList"
                                                onChange={this.onArticleChange}
                                                label="Article"
                                                value={selectedArticle}
                                                required
                                                error={error.article_id}
                                            >
                                                {articles.map((row) => (
                                                    <MenuItem
                                                        key={row.id}
                                                        value={row.id}
                                                    >
                                                        {row.label}
                                                    </MenuItem>
                                                ))}
                                            </Select>
                                        </FormControl>
                                    </Grid>
                                    <Grid item xs={3}>
                                        <TextField
                                            id="box"
                                            label="Box"
                                            type="text"
                                            name="box"
                                            required={true}
                                            fullWidth
                                            onChange={this.handleOnChange}
                                            error={error.box}
                                        />
                                    </Grid>
                                    <Grid item xs={3}>
                                        <TextField
                                            id="serialnumber"
                                            label="Serial Number"
                                            type="text"
                                            name="serialnumber"
                                            required={snMandatory}
                                            fullWidth
                                            onChange={this.handleOnChange}
                                            error={error.serialnumber}
                                        />
                                    </Grid>


                                    <Grid item xs={6}>
                                        <TextField
                                            id="storage_place"
                                            label="Storage Place"
                                            type="text"
                                            name="storage_place"
                                            required={currentStation && currentStation.id > 0 && currentStation.storage_place_ismandatory}
                                            fullWidth
                                            onChange={this.handleOnChange}
                                            error={error.storage_place_ismandatory}
                                        />
                                    </Grid>
                                    <Grid item xs={6}>
                                        <TextField
                                            type="text"
                                            id="ts_inbound"
                                            name="ts_inbound"
                                            label="Timestamp Inbound"
                                            required
                                            value={moment().format("yyyy-MM-DD HH:mm:ss")}
                                            fullWidth
                                            InputProps={{ readOnly: true, disableUnderline: true }}
                                        />
                                    </Grid>

                                </Grid>
                            </Grid>

                            <Grid item xs={4}>
                                <Button variant="contained" color="secondary" onClick={this.handleOnBackClick}>Back</Button>
                            </Grid>
                            <Grid item xs={2}>
                            </Grid>
                            <Grid item xs={4}>
                                <Button variant="contained" onClick={this.handleOnAddClick}>Add</Button>
                            </Grid>
                        </Grid>
                    </Grid>
                </div>
        }
        else {
            header =
                <div>
                    <Grid container spacing={3}>
                        <Grid item xs={4}>
                            <FormControl sx={{ m: 0, width: 300 }}>
                                <InputLabel id="stationList">Stations</InputLabel>
                                <Select
                                    id="stationList"
                                    name="stationList"
                                    value={selectedStation}
                                    label="Station"
                                    onChange={this.onStationChange}
                                >
                                    <MenuItem key={0} value={0}>ALL STATIONS</MenuItem>
                                    {stations.map((row) => (
                                        <MenuItem key={row.id} value={row.id}>{row.acronym} - {row.name}</MenuItem>
                                    ))}
                                </Select>
                            </FormControl>
                        </Grid>
                        <Grid item xs={2}>
                            <RadioGroup
                                aria-labelledby="demo-radio-buttons-group-label"
                                defaultValue="in"
                                name="count"
                                onChange={this.handleFilterInOut}
                            >
                                <FormControlLabel value={1} control={<Radio size="small" />} label="in" />
                                <FormControlLabel value={0} control={<Radio size="small" />} label="out" />
                                <FormControlLabel value={null} control={<Radio size="small" />} label="in & out" />
                            </RadioGroup>
                        </Grid>
                        <Grid item xs={2}>
                            <FormGroup>
                                <FormControlLabel control={
                                    <Switch
                                        id="showAllSwitch"
                                        //defaultChecked
                                        Checked={showAll}
                                        onChange={((e) => this.handleShowAllSwitch(e))} />
                                } label="more..." />
                            </FormGroup>
                        </Grid>
                        <Grid item xs={2}>
                            
                        </Grid>
                        <Grid item xs={2}>
                            {this.user.role != "Supervisor" 
                                ?
                                 <Button
                                    variant="contained"
                                    onClick={this.handleOnAddItemClick}                                    
                                >
                                    Add Item
                                </Button>
                                : 
                                ""
                            }
                            
                        </Grid>
                    </Grid>
                    <br />
                </div>

            if (stockItems && stockItems.length) {
                content =
                    <div>
                        
                        <Paper sx={{ width: '100%', overflow: 'hidden' }}>
                            <TableContainer component={Paper} sx={{ maxHeight: 700}} >
                                <Table className={classes.table} aria-label="simple table"  >
                                    <TableHead>
                                        <TableRow>
                                            <TableCell><Typography fontSize={showAll === true ? "100%" : "-0%"}>ID</Typography></TableCell>
                                            <TableCell>Artikel</TableCell>
                                            <TableCell>Station</TableCell>
                                            <TableCell>Box</TableCell>
                                            <TableCell>Storage<br />Place</TableCell>
                                            <TableCell>S/N</TableCell>
                                            <TableCell>Age</TableCell>
                                            <TableCell>Count</TableCell>
                                            <TableCell><Typography fontWeight={"bold"} fontSize={showAll === true ? "100%" : "-0%"}>Inbound</Typography></TableCell>
                                            <TableCell><Typography fontWeight={"bold"} fontSize={showAll === true ? "100%" : "-0%"}>Outbound</Typography></TableCell>
                                            <TableCell><Typography fontWeight={"bold"} fontSize={showAll === true ? "100%" : "-0%"}>Inventorycheck</Typography></TableCell>
                                            <TableCell>ACTIONS</TableCell>
                                        </TableRow>
                                    </TableHead>
                                    <TableBody>
                                        {stockItems.map((row, index) => (
                                            <TableRow key={index} selected={row.id == findIndex ? true : false}>
                                                <TableCell ><Typography fontSize={showAll === true ? "100%" : "-0%"}>{row.id}</Typography></TableCell>
                                                <TableCell >{index} {row.label}</TableCell>
                                                <TableCell >{row.acronym}</TableCell>
                                                <TableCell >{row.box}</TableCell>
                                                <TableCell >{row.storage_place}</TableCell>
                                                <TableCell >{row.serialnumber}</TableCell>
                                                <TableCell >
                                                    <Typography
                                                        color={this.getWarnstufe(row.datetime, row.maxAge, row.datetime_outofstock).color}
                                                        fontWeight={this.getWarnstufe(row.datetime, row.maxAge, row.datetime_outofstock).fontWeight}
                                                        fontSize={this.getWarnstufe(row.datetime, row.maxAge, row.datetime_outofstock).fontSize}
                                                    >
                                                        {Math.ceil(moment.duration(moment().diff(row.datetime)).asDays())}
                                                    </Typography>
                                                </TableCell>
                                                <TableCell><Typography color={row.sum === 0 ? "error" : "default"}>{row.count}</Typography></TableCell>
                                                <TableCell><Typography fontSize={showAll === true ? "100%" : "-0%"}>{row.datetime}<br /><strong>{row.outsourced_not}</strong></Typography></TableCell>
                                                <TableCell><Typography fontSize={showAll === true ? "100%" : "-0%"}>{row.datetime_outofstock}<br /><strong>{row.outsourced_ops}</strong></Typography></TableCell>
                                                <TableCell><Typography fontSize={showAll === true ? "100%" : "-0%"}>{row.datetime_lastinventorycheck}</Typography></TableCell>
                                                <TableCell>
                                                    {row.sum === 0 ? 
                                                        "" 
                                                        : 
                                                        <Tooltip
                                                        title="Inventorycheck"
                                                        arrow>
                                                            <IconButton
                                                                edge="start"
                                                                aria-label="edit"
                                                                onClick={((e) => this.handleOnEditClick(e, row))}
                                                                color={"success"}                                                            
                                                                m={3}
                                                            >
                                                                <LightbulbIcon />
                                                            </IconButton>
                                                        </Tooltip>
                                                    }
                                                        
                                                    {row.sum === 0 ? 
                                                        "" 
                                                        : 
                                                        <Tooltip
                                                            title="Outbound"
                                                            arrow
                                                        >
                                                            <IconButton
                                                                edge="start"
                                                                aria-label="outbound"
                                                                onClick={((e) => this.handleOnOutboundClick(e, row))}
                                                                color={"error"}                                                            
                                                                m={3}
                                                            >
                                                                <OutboundIcon />
                                                            </IconButton>
                                                        </Tooltip>
                                                    }
                                                    <Tooltip
                                                        title="Details"
                                                        arrow
                                                    >
                                                        <IconButton
                                                            edge="start"
                                                            aria-label="details"
                                                            onClick={((e) => this.handleOnDetailsClick(e, row))}
                                                            color={"secondary"}
                                                            m={3}
                                                        >
                                                            <FeedIcon />
                                                        </IconButton>
                                                    </Tooltip>
                                                </TableCell>
                                            </TableRow>
                                        ))}
                                        <Waypoint onEnter={this.handleWaypointEnter} onLeave={() => { console.log("on onLeave");}}></Waypoint>
                                    </TableBody>
                                </Table>
                            </TableContainer>
                            
                        </Paper>

                        {/*info dialog INVENTORYCHECK*/}
                        
                        {/*info dialog OUTBOUNDBOOKING*/}
                        {/*info dialog FULL DETAILS*/}
                        
                    </div >
            } else {
                content =
                    <div>
                        <TableContainer component={Paper}>
                            <Table className={classes.table} aria-label="simple table">
                                <TableHead>
                                    <TableRow>
                                        <TableCell><Typography fontSize={showAll === true ? "100%" : "-0%"}>ID</Typography></TableCell>
                                        <TableCell>Artikel</TableCell>
                                        <TableCell>Station</TableCell>
                                        <TableCell>Box</TableCell>
                                        <TableCell>Storage<br />Place</TableCell>
                                        <TableCell>S/N</TableCell>
                                        <TableCell>Age</TableCell>
                                        <TableCell>Count</TableCell>
                                        <TableCell><Typography fontWeight={"bold"} fontSize={showAll === true ? "100%" : "-0%"}>Inbound</Typography></TableCell>
                                        <TableCell><Typography fontWeight={"bold"} fontSize={showAll === true ? "100%" : "-0%"}>Outbound</Typography></TableCell>
                                        <TableCell><Typography fontWeight={"bold"} fontSize={showAll === true ? "100%" : "-0%"}>Inventorycheck</Typography></TableCell>
                                        <TableCell>ACTIONS</TableCell>
                                    </TableRow>
                                </TableHead>
                                <TableBody>
                                    <TableRow>
                                        <TableCell colSpan={"12"} align='center'><Typography fontWeight={"bold"} fontSize={"200%"} color={"error"}>Empty list...<br />List is loading now....<br />If you can't see anything here in a few, please try hitting 'REFRESH'.<br /></Typography></TableCell>
                                    </TableRow>
                                </TableBody>
                            </Table>
                        </TableContainer>
                    </div>
            }
        }

        if(dialogViewInventory){
            content = <Dialog
                maxWidth={"600px"}
                open={dialogViewInventory}
                onClose={this.handleDetailsClose}
                TransitionComponent={Transition}
                >
                <AppBar sx={{ position: 'relative' }} style={{ background: '#388e3c' }} >
                    <Toolbar>
                        <IconButton
                            edge="start"
                            color="inherit"
                            onClick={this.handleDetailsClose}
                            aria-label="close"
                        >
                            <CloseIcon />
                        </IconButton>
                        <Typography sx={{ ml: 2, flex: 1 }} variant="h6" component="div">
                            Inventorycheck for Item-ID {selectedStockItem.id}
                        </Typography>
                    </Toolbar>
                </AppBar>
                {submitInfo}
                <Grid container spacing={3} m={3}>
                    <Grid item xs={10}>
                        <DialogContent>
                            <Grid container spacing={3}>
                                <Grid item xs={4}>
                                    <TextField
                                        name="label"
                                        label="Article"
                                        fullWidth
                                        InputProps={{ readOnly: true, disableUnderline: true }}
                                        value={selectedStockItem.label}
                                        variant='filled'
                                    />
                                </Grid>
                                <Grid item xs={2}>
                                    <TextField
                                        name="box"
                                        label="Box"
                                        fullWidth
                                        InputProps={{ readOnly: true, disableUnderline: true }}
                                        value={selectedStockItem.box}
                                        variant='filled'
                                    />
                                </Grid>
                                <Grid item xs={2}>
                                    <TextField
                                        name="storage_place"
                                        label="Storage Place"
                                        fullWidth
                                        InputProps={{ readOnly: true, disableUnderline: true }}
                                        value={selectedStockItem.storage_place}
                                        variant='filled'
                                    />
                                </Grid>
                                <Grid item xs={2}>
                                    <TextField
                                        name="serialnumber"
                                        label="S/N"
                                        fullWidth
                                        InputProps={{ readOnly: true, disableUnderline: true }}
                                        value={selectedStockItem.serialnumber}
                                        variant='filled'
                                    />
                                </Grid>
                                <Grid item xs={5}>
                                    <TextField
                                        name="inbound_ts"
                                        label="Inbound Timestamp"
                                        fullWidth
                                        InputProps={{ readOnly: true, disableUnderline: true }}
                                        value={selectedStockItem.datetime}
                                        variant='filled'
                                    />
                                </Grid>
                                <Grid item xs={5}>
                                    <TextField
                                        name="inbound_waybill"
                                        label="Inbound HWB"
                                        fullWidth
                                        InputProps={{ readOnly: true, disableUnderline: true }}
                                        value={selectedStockItem.outsourced_not}
                                        variant='filled'
                                    />
                                </Grid>
                                <Grid item xs={10}>
                                    <TextField
                                        name="inventorycheck_ts"
                                        id="inventorycheck_ts"
                                        label="Inventorycheck Timestamp"
                                        fullWidth
                                        InputProps={{ disableUnderline: true }}
                                        required
                                        value={moment().format("yyyy-MM-DD HH:mm:ss")}
                                    />
                                </Grid>
                            </Grid>
                        </DialogContent>

                        <DialogActions>
                            <Grid container spacing={3}>
                                <Grid item xs={10}>
                                    <Button
                                        variant="contained"
                                        color="success"
                                        fullWidth
                                        size='large'
                                        onClick={((e) => this.handleOnInventoryCheckClick(e, selectedStockItem.id))}
                                    >
                                        INVENTORY CHECK
                                    </Button>
                                </Grid>
                            </Grid>
                        </DialogActions>
                    </Grid>
                </Grid>
                {submitInfo}
            </Dialog >
        }
        else if(dialogViewOutbound){
            content = <Dialog
                    maxWidth={"600px"}
                    open={dialogViewOutbound}
                    onClose={this.handleDetailsClose}
                    TransitionComponent={Transition}
                >
                <AppBar sx={{ position: 'relative' }} style={{ background: '#d32f2f' }} >
                    <Toolbar>
                        <IconButton
                            edge="start"
                            color="inherit"
                            onClick={this.handleDetailsClose}
                            aria-label="close"
                        >
                            <CloseIcon />
                        </IconButton>
                        <Typography sx={{ ml: 2, flex: 1 }} variant="h6" component="div">
                            Outboundbooking of Item-ID {selectedStockItem.id}
                        </Typography>
                    </Toolbar>
                </AppBar>
                {submitInfo}
                <Grid container spacing={3} m={3}>
                    <Grid item xs={10}>
                        <DialogContent>
                            <Grid container spacing={3}>
                                <Grid item xs={4}>
                                    <TextField
                                        name="label"
                                        label="Article"
                                        fullWidth
                                        InputProps={{ readOnly: true, disableUnderline: true }}
                                        value={selectedStockItem.label}
                                        variant='filled'
                                    />
                                </Grid>
                                <Grid item xs={2}>
                                    <TextField
                                        name="box"
                                        label="Box"
                                        fullWidth
                                        InputProps={{ readOnly: true, disableUnderline: true }}
                                        value={selectedStockItem.box}
                                        variant='filled'
                                    />
                                </Grid>
                                <Grid item xs={2}>
                                    <TextField
                                        name="storage_place"
                                        label="Storage Place"
                                        fullWidth
                                        InputProps={{ readOnly: true, disableUnderline: true }}
                                        value={selectedStockItem.storage_place}
                                        variant='filled'
                                    />
                                </Grid>
                                <Grid item xs={2}>
                                    <TextField
                                        name="serialnumber"
                                        label="S/N"
                                        fullWidth
                                        InputProps={{ readOnly: true, disableUnderline: true }}
                                        value={selectedStockItem.serialnumber}
                                        variant='filled'
                                    />
                                </Grid>
                                <Grid item xs={5}>
                                    <TextField
                                        name="inbound_ts"
                                        label="Inbound Timestamp"
                                        fullWidth
                                        InputProps={{ readOnly: true, disableUnderline: true }}
                                        value={selectedStockItem.datetime}
                                        variant='filled'
                                    />
                                </Grid>
                                <Grid item xs={5}>
                                    <TextField
                                        name="inbound_waybill"
                                        label="Inbound HWB"
                                        fullWidth
                                        InputProps={{ readOnly: true, disableUnderline: true }}
                                        value={selectedStockItem.outsourced_not}
                                        variant='filled'
                                    />
                                </Grid>
                                <Grid item xs={5}>
                                    <TextField
                                        name="outbound_ts"
                                        label="Outbound Timestamp"
                                        fullWidth
                                        InputProps={{ readOnly: true, disableUnderline: true }}
                                        required
                                        value={moment().format("yyyy-MM-DD HH:mm:ss")}
                                    />
                                </Grid>
                                <Grid item xs={5}>
                                    <TextField
                                        type="text"
                                        id="outboundHWB"
                                        name="outboundHWB"
                                        label="Outbound HWB"
                                        fullWidth
                                        InputProps={{ maxLength: 12 }}
                                        required
                                        error={error.outboundHWB}
                                        onChange={this.handleOnChange}
                                    />
                                </Grid>
                            </Grid>
                        </DialogContent>

                        <DialogActions>
                            <Grid container spacing={3}>
                                <Grid item xs={10}>
                                    <Button
                                        variant="contained"
                                        color="error"
                                        fullWidth
                                        size='large'
                                        onClick={((e) => this.handleOnOutboundBookClick(e, selectedStockItem.id))}
                                    >
                                        BOOK OUTBOUND
                                    </Button>
                                </Grid>
                            </Grid>
                        </DialogActions>
                    </Grid>
                </Grid>
                {submitInfo}
                </Dialog >
        
        }
        else if(dialogViewDetails){
            content = <Dialog
                maxWidth={"600px"}
                open={dialogViewDetails}
                onClose={this.handleDetailsClose}
                TransitionComponent={Transition}
            >
                <AppBar sx={{ position: 'relative' }} style={{ background: '#ab47bc' }} >
                    <Toolbar>
                        <IconButton
                            edge="start"
                            color="inherit"
                            onClick={this.handleDetailsClose}
                            aria-label="close"
                        >
                            <CloseIcon />
                        </IconButton>
                        <Typography sx={{ ml: 2, flex: 1 }} variant="h6" component="div">
                            Full details of Item-ID {selectedStockItem.id}
                        </Typography>
                    </Toolbar>
                </AppBar>
                {submitInfo}
                <Grid container spacing={3} m={3}>
                    <Grid item xs={10}>
                        <DialogContent>
                            <Grid container spacing={3}>
                                <Grid item xs={4}>
                                    <TextField
                                        name="label"
                                        label="Article"
                                        fullWidth
                                        InputProps={{ readOnly: true, disableUnderline: true }}
                                        value={selectedStockItem.label + " (#" + selectedStockItem.article_id + ")"}
                                        variant='filled'
                                    />
                                </Grid>
                                <Grid item xs={2}>
                                    <TextField
                                        name="box"
                                        label="Box"
                                        fullWidth
                                        InputProps={{ readOnly: true, disableUnderline: true }}
                                        value={selectedStockItem.box}
                                        variant='filled'
                                    />
                                </Grid>
                                <Grid item xs={2}>
                                    <TextField
                                        name="storage_place"
                                        label="Storage Place"
                                        fullWidth
                                        InputProps={{ readOnly: true, disableUnderline: true }}
                                        value={selectedStockItem.storage_place}
                                        variant='filled'
                                    />
                                </Grid>
                                <Grid item xs={2}>
                                    <TextField
                                        name="serialnumber"
                                        label="S/N"
                                        fullWidth
                                        InputProps={{ readOnly: true, disableUnderline: true }}
                                        value={selectedStockItem.serialnumber}
                                        variant='filled'
                                    />
                                </Grid>
                                <Grid item xs={5}>
                                    <TextField
                                        name="inbound_ts"
                                        label="Inbound Timestamp"
                                        fullWidth
                                        InputProps={{ readOnly: true, disableUnderline: true }}
                                        value={selectedStockItem.datetime}
                                        variant='filled'
                                    />
                                </Grid>
                                <Grid item xs={5}>
                                    <TextField
                                        name="inbound_waybill"
                                        label="Inbound HWB"
                                        fullWidth
                                        InputProps={{ readOnly: true, disableUnderline: true }}
                                        value={selectedStockItem.outsourced_not}
                                        variant='filled'
                                    />
                                </Grid>
                                <Grid item xs={5}>
                                    <TextField
                                        name="inventorycheck_ts"
                                        id="inventorycheck_ts"
                                        label="Inventorycheck Timestamp"
                                        fullWidth
                                        InputProps={{ readOnly: true, disableUnderline: true }}
                                        value={selectedStockItem.datetime_lastinventorycheck}
                                        variant='filled'
                                    />
                                </Grid>
                                <Grid item xs={5}>
                                    <TextField
                                        name="acronym"
                                        id="acronym"
                                        label="Station"
                                        fullWidth
                                        InputProps={{ readOnly: true, disableUnderline: true }}
                                        value={selectedStockItem.acronym + " (#" + selectedStockItem.station_id + ")"}
                                        variant='filled'
                                    />
                                </Grid>
                                <Grid item xs={5}>
                                    <TextField
                                        name="outbound_ts"
                                        label="Outbound Timestamp"
                                        fullWidth
                                        InputProps={{ readOnly: true, disableUnderline: true }}
                                        value={selectedStockItem.datetime_outofstock}
                                        variant='filled'
                                    />
                                </Grid>
                                <Grid item xs={5}>
                                    <TextField
                                        type="text"
                                        id="outboundHWB"
                                        name="outboundHWB"
                                        label="Outbound HWB"
                                        fullWidth
                                        InputProps={{ readOnly: true, disableUnderline: true }}
                                        value={selectedStockItem.outsourced_ops}
                                        variant='filled'
                                    />
                                </Grid>
                            </Grid>
                        </DialogContent>

                        <DialogActions>
                            <Grid container spacing={3}>
                                <Grid item xs={10}>
                                    <Button
                                        variant="contained"
                                        color="secondary"
                                        fullWidth
                                        size='large'
                                        onClick={this.handleDetailsClose}
                                    >
                                        CLOSE
                                    </Button>
                                </Grid>
                            </Grid>
                        </DialogActions>
                    </Grid>
                </Grid>
                {submitInfo}
            </Dialog >
        }

        return (
            <div className={classes.root}>
                {loadingIndicator}
                {header}
                {content}
            </div>
        );
    }
}

export default withStyles(useStyles)(StockItems)