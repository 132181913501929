import moment from "moment";
import { withStyles } from '@mui/styles';
import React from 'react';
import axios from 'axios';
import CircularProgress from '@mui/material/CircularProgress';
import { TextField, Button, Stack } from '@mui/material';
import FormControl from '@mui/material/FormControl';
import Select from '@mui/material/Select';
import { InputLabel } from '@mui/material';
import MenuItem from '@mui/material/MenuItem';
import Grid from '@mui/material/Grid';
import Autocomplete from '@mui/material/Autocomplete';

import Card from '@mui/material/Card';
import CardContent from '@mui/material/CardContent';
import Typography from '@mui/material/Typography';
import AddIcon from '@mui/icons-material/Add';
import RemoveIcon from '@mui/icons-material/Remove';
import MuiAlert from '@mui/material/Alert';
import Divider from '@mui/material/Divider';
import SendIcon from '@mui/icons-material/Send';

import { ContentPasteOffSharp } from '@mui/icons-material';

const Alert = React.forwardRef(function Alert(props, ref) {
    return <MuiAlert elevation={6} ref={ref} variant="filled" {...props} />;
});

const useStyles = (theme) => ({
    root: {

    },
    table: {
        minWidth: 450,
    },
});

class Order extends React.Component {

    constructor(props) {
        super(props);
        this.timeout = 0;
        this.user = JSON.parse(localStorage.getItem("user"));
        this.distanceCalculation = "byHere" // or by byBirdsFly;
        this.state = {
            formData: {
                editId: 0,
                recipient_company: "",
                recipient_name: "",
                recipient_phone: "",
                recipient_address: "",
                recipient_city: "",
                recipient_zipcode: "",
                recipient_country: "",
                additional_infos: "",
                recipient_latitude: 0.0,
                recipient_longitude: 0.0,
                customer_reference: "",
                service: "DI",
                weight: 0,
                packages: 0
            },
            error: {
                recipient_company: false,
                recipient_name: false,
                recipient_address: false,
                recipient_zipcode: false,
                recipient_city: false,
                recipient_country: false,
                artice: false
            },
            addressSearch: "",
            stockItems: [],
            articles: [],
            selectedArticle: {
                0: 0
            },
            customers: [],
            selectedCustomer: {},
            products: [],
            productCount: 1,
            countValues: {},
            addressResults: [],
            recipientResults: [],
            stockItemByNearestDistance: [],
            showsuccess: false,
            showerror: false,
            addressRequestCompleted: true,
            saveButtonName: "Einreichen",
            deactiveInput: false
        };

    }
    componentDidMount() {
        const { editItem } = this.props;
        this.getArticle();
        this.getCustomers();
        this.getCustomerRelation();
        //console.log("editItem", editItem);
        if (editItem && editItem.recipient_company && editItem.recipient_company.length > 0) {
            //workarround to re render jsx
            this.setState(({
                productCount: 0,
                loading: true
            }))
            setTimeout(() => {
                //console.log("editItem2", editItem);
                let { formData, selectedArticle, countValues, stockItemByNearestDistance } = this.state;
                formData.editId = editItem.id;
                formData.recipient_company = editItem.recipient_company;
                formData.recipient_name = editItem.recipient_name;
                formData.recipient_phone = editItem.recipient_phone;
                formData.recipient_address = editItem.recipient_address;
                formData.recipient_city = editItem.recipient_city;
                formData.recipient_country = editItem.recipient_country;
                formData.recipient_zipcode = editItem.recipient_zipcode;
                formData.additional_infos = editItem.additional_infos;
                formData.recipient_latitude = editItem.recipient_latitude;
                formData.recipient_latitude = editItem.recipient_latitude;
                formData.customer_reference = editItem.customer_reference;
                formData.service = editItem.service;
                formData.weight = editItem.weight;
                formData.packages = editItem.packages;

                for (let i = 0; i < editItem.articles.length; i++) {
                    selectedArticle[i] = editItem.articles[i].article_id;
                    countValues[i] = editItem.articles[i].count;
                }

                stockItemByNearestDistance.push({
                    acronym: editItem.acronym,
                    label: editItem.articles[0].label
                })

                this.setState({
                    formData: formData,
                    selectedArticle: selectedArticle,
                    countValues: countValues,
                    saveButtonName: "Bearbeiten",
                    productCount: editItem.articles.length,
                    stockItemByNearestDistance: stockItemByNearestDistance,
                    loading: false

                });

            }, 500);

        }

    }

    handleOnChange = (e) => {
        let { formData } = this.state;
        formData[e.target.id] = e.target.value || "";
        //console.log("formData", formData);
        this.setState({
            formData: formData
        });
        this.checkForGeoCoding();
    }

    checkForGeoCoding() {
        let { formData } = this.state;
        if (formData.recipient_latitude == 0 && formData.recipient_longitude == 0) {
            if (formData.recipient_address.length > 3 && formData.recipient_city.length > 3 && formData.recipient_zipcode.length > 4 && formData.recipient_country.length == 2) {
                this.getAddressCompletionStructed(formData.recipient_city, formData.recipient_zipcode, formData.recipient_address, formData.recipient_country);
            }
        }
    }

    handleOnChangeRecipient = (e, newinput) => {
        if (newinput && newinput.length > 0) {
            let { formData } = this.state;
            this.getRecipientCompletion(newinput);
            formData.recipient_company = newinput;
            this.setState({
                formData: formData
            })
        }
    }

    handleOnRecipientSelected = (e, newinput) => {
        //console.log("newinput selected", newinput);
        let { formData, recipientResults } = this.state;
        //console.log("recipientResults", recipientResults);
        const rFound = recipientResults.filter((r) => r.recipient_company == newinput)[0];
        formData.recipient_company = rFound.recipient_company;
        formData.recipient_name = rFound.recipient_name;
        formData.recipient_address = rFound.recipient_address;
        formData.recipient_city = rFound.recipient_city != "null" ? rFound.recipient_city : "";
        formData.recipient_zipcode = rFound.recipient_zipcode != "null" ? rFound.recipient_zipcode : "";
        formData.recipient_phone = rFound.recipient_phone != "null" ? rFound.recipient_phone : "";
        formData.additional_infos = rFound.additional_infos != "null" ? rFound.additional_infos : "";
        formData.recipient_country = rFound.recipient_country != "null" ? rFound.recipient_country : "";
        formData.recipient_latitude = rFound.recipient_latitude;
        formData.recipient_longitude = rFound.recipient_longitude;

        this.setState({
            //addressResults: response.data.result,
            formData: formData
        })

        this.getStockItemsByNearestNeighbor(formData.recipient_country, formData.recipient_latitude, formData.recipient_longitude);
    }

    handleOnChangeAddress = (e, newinput) => {
        let { addressRequestCompleted } = this.state;
        if (newinput && newinput.length > 0 && addressRequestCompleted) {

            if (this.timeout) clearTimeout(this.timeout);
            this.timeout = setTimeout(() => {
                //search function
                //console.log("serarch now");
                this.setState({
                    addressRequestCompleted: false,
                    //loading: true,
                    addressResults: []
                })
                this.getAddressCompletion(newinput);
            }, 1000);
        }
    }

    handleOnAddressSelected = (e, newinput) => {
        const { addressResults } = this.state;
        let index = e.target;
        //console.log(e.target); 
        //console.log("addressResults", addressResults);
        if (addressResults && addressResults.length > 0) {
            this.getDetailAddressInformation(addressResults[0])
        }
    }

    onArticleChange = (e) => {
        let { selectedArticle, articles, countValues } = this.state;
        //console.log("e.target", e.target)
        let i = e.target.name.split("-")[1];
        let id = e.target.value;
        //console.log("selectedArticle", selectedArticle);
        const item = articles.filter((artice) => artice.id == id)[0];
        //console.log("onArticleChange.item", item);
        selectedArticle[i] = id;
        countValues[i] = 1;
        this.setState({
            selectedArticle: selectedArticle,
            countValues: countValues
        });
        this.checkStockItemsByArticleAndAmount(i);
    }

    onCustomerChange = (e) => {
        let { selectedCustomer, customers } = this.state;
        let i = e.target.name.split("-")[1];
        let id = e.target.value;
        //console.log("selectedCustomer", selectedCustomer);
        selectedCustomer = customers.filter((customer) => customer.id == id)[0];
        this.setState({
            selectedCustomer: selectedCustomer,
        });
        this.updateCustomerRelation(selectedCustomer);
    }

    onCountChange = (e) => {
        let { countValues } = this.state;
        let i = e.target.name.split("-")[1]
        countValues[i] = e.target.value;
        this.setState({
            countValues: countValues
        });
        this.checkStockItemsByArticleAndAmount(i);
    }

    onServiceChange = (e) => {
        let { formData } = this.state;
        formData.service = e.target.value;
        this.setState({
            formData: formData
        });
    }

    checkStockItemsByArticleAndAmount(i) {
        let { countValues, selectedArticle, stockItems, stockItemByNearestDistance } = this.state;
        //console.log("checkStockItemsByArticleAndAmount countValues", countValues);
        //console.log("checkStockItemsByArticleAndAmount selectedArticle", selectedArticle);
        if (countValues[i] && selectedArticle[i]) {
            //console.log("checkStockItemsByArticleAndAmount", countValues[i], selectedArticle[i]);
            console.log("stockItems", stockItems);//TODO! Log stmt, time selection issue
            if (stockItems && stockItems.length > 0) {
                let items = stockItems.filter((stockItem => stockItem.article_id == selectedArticle[i] && stockItem.sum >= countValues[i]));
                if (items && items.length > 0) {
                    console.log("checkStockItemsByArticleAndAmount -> items", items[0])

                    var index = stockItemByNearestDistance[i]
                    if (index !== -1) {
                        stockItemByNearestDistance[i] = items[0]
                    } else {
                        stockItemByNearestDistance.push(items[0]);
                    }

                    // ETA Calculation - START
                    let prepTime = 60;
                    let travelTime = 0;
                    let travelDistance = Math.ceil(stockItemByNearestDistance[0].distance);
                    //console.log("travelDistance", travelDistance);
                    if (travelDistance < 20) {
                        travelTime = 60;
                    } else if (travelDistance >= 20 && travelDistance < 60) {
                        travelTime = Math.ceil(travelDistance / 0.8);
                    } else {
                        travelTime = Math.ceil(travelDistance / 1.5);
                    }
                    //console.log("travelTime", travelTime);
                    let eta = moment().add(prepTime + travelTime, 'm');
                    let timeRounded = 15 - (eta.minute() % 15);
                    eta = moment(eta).add(timeRounded, 'm');
                    //console.log("ETA", moment(eta).format("DD.MM.YYYY HH:mm"));

                    stockItemByNearestDistance[0].eta = eta;
                    // ETA Calculation - END

                    console.log("stockItemByNearestDistance", stockItemByNearestDistance)
                }
            }
        }
    }

    handleOnMoreProductsClicked = (e) => {
        let { selectedArticle } = this.state;
        const count = Object.keys(selectedArticle).length;
        for (let i = 0; i <= count; i++) {
            selectedArticle[i] = selectedArticle[i];
        }
        this.setState({
            selectedArticle: selectedArticle
        })
    }

    handleOnLessProductsClicked = (e) => {
        let { selectedArticle } = this.state;
        let i = e.target.id.replace("less_", "");

        if (i && i.length > 0) {
            delete selectedArticle[i];
        }

        let newSelectedArticle = {};
        let j = 0;
        for (let key in selectedArticle) {
            newSelectedArticle[j] = selectedArticle[key];
            j++;
        }

        //console.log("newSelectedArticle", selectedArticle);
        this.setState({
            selectedArticle: newSelectedArticle
        })
    }

    handleSubmit = (event) => {
        event.preventDefault();
        //console.log(firstName, lastName, email, dateOfBirth, password) 
        const { formData, selectedArticle, articles, countValues, stockItemByNearestDistance, selectedCustomer, error } = this.state;
        //console.log(formData, selectedArticle, countValues);
        let anyError = false;
        //company
        if (formData.recipient_company.length == 0) {
            error.recipient_company = true;
        }
        else {
            error.recipient_company = false;
        }

        //zipcode
        if (formData.recipient_zipcode.length == 0) {
            error.recipient_zipcode = true;
        }
        else {
            error.recipient_zipcode = false;
        }

        //city
        if (formData.recipient_city.length == 0) {
            error.recipient_city = true;
        }
        else {
            error.recipient_city = false;
        }

        //address
        if (formData.recipient_address.length == 0) {
            error.recipient_address = true;
        }
        else {
            error.recipient_address = false;
        }

        //article
        if (selectedArticle.length == 0) {
            error.artice = true;
        }
        else {
            error.artice = false;
        }

        for (let key in error) {
            if (error[key] === true) {
                anyError = true
                break;
            }
        }

        this.setState({
            error: error
        })

        let weight = 0;
        if (stockItemByNearestDistance && stockItemByNearestDistance.length > 0) {
            for (let i = 0; i < stockItemByNearestDistance.length; i++) {
                weight += stockItemByNearestDistance[i].weight;
            }
        }

        formData.weight = weight;
        formData.packages = stockItemByNearestDistance.length;

        if (!anyError) {
            let order = {
                recipient_company: formData.recipient_company.replaceAll("'", " "),
                recipient_name: formData.recipient_name.replaceAll("'", " "),
                recipient_phone: formData.recipient_phone,
                recipient_zipcode: formData.recipient_zipcode,
                recipient_city: formData.recipient_city.replaceAll("'", " "),
                recipient_country: formData.recipient_country,
                recipient_address: formData.recipient_address.replaceAll("'", " "),
                recipient_latitude: formData.recipient_latitude,
                recipient_longitude: formData.recipient_longitude,
                additional_infos: formData.additional_infos.replaceAll("'", " "),
                customer_reference: formData.customer_reference,
                customer_id: selectedCustomer.id,
                station_id: stockItemByNearestDistance[0].station_id,
                station_address: stockItemByNearestDistance[0].address.replaceAll("'", " "),
                station_name: stockItemByNearestDistance[0].name.replaceAll("'", " "),
                station_zipcode: stockItemByNearestDistance[0].zipcode,
                station_city: stockItemByNearestDistance[0].city.replaceAll("'", " "),
                station_country: stockItemByNearestDistance[0].country,
                station_email: stockItemByNearestDistance[0].email,
                station_acronym: stockItemByNearestDistance[0].acronym,
                status_id: 1,
                articles: [],
                email: {
                    customer: selectedCustomer,
                    stockItems: stockItemByNearestDistance
                },
                service: formData.service,
                user_id: this.user.id,
                pickup_date: ((moment().get('hour') < 23) ? moment().format("YYYY-MM-DD") : moment().add(1, 'day').format("YYYY-MM-DD")),
                pickup_time_from: ((moment().get('hour') < 23) ? moment().format("HH:mm") : "00:01"),
                pickup_time_till: "23:59",
                //pickup_time_till: moment().add(6, "hours").format("HH") + ":00",
                weight: formData.weight,
                packages: formData.packages
            }
            if (formData.editId) {
                order.id = formData.editId;
            }
           //console.log("handleSubmit.selectedArticle", selectedArticle);
            for (let key in selectedArticle) {
                for (let i = 0; i < countValues[key]; i++) {
                    const item = articles.filter((article) => article.id == selectedArticle[key])[0];
                    //console.log("handleSubmit.item", item);
                    order.articles.push({
                        article_id: selectedArticle[key],
                        article_label: item.label,
                        count: 1
                    })
                }
            }
            console.log("order", order);
            this.publishOrder(order, (order.id ? "PUT" : "POST"));
        }
    }

    publishOrder(orderdata, method) {
        //console.log("orderdata", orderdata);
        //console.log("method", method);
        this.setState({
            loading: true,
            deactiveInput: true
        })
        axios({
            method: method,
            url: `${process.env.REACT_APP_API_URL}/order`,
            data: orderdata,
            auth: {
                username: localStorage.getItem('username') || "",
                password: localStorage.getItem('password') || ""
            }
        })
            .then((response) => {
                //console.log(response.data);
                this.setState({
                    loading: false,
                    showsuccess: true
                })

                setTimeout(() => {
                    this.setState({
                        deactiveInput: false,
                        showsuccess: false,
                        formData: {
                            editId: 0,
                            recipient_company: "",
                            recipient_name: "",
                            recipient_phone: "",
                            recipient_address: "",
                            recipient_city: "",
                            recipient_zipcode: "",
                            recipient_country: "",
                            additional_infos: "",
                            recipient_latitude: 0.0,
                            recipient_longitude: 0.0,
                            customer_reference: "",
                            service: "DI"
                        },
                        selectedArticle: {
                            0: 0
                        },
                        stockItemByNearestDistance: []
                    })
                }, 5000)//wait 5 seconds
            })
            .catch(function (error) {

                this.setState({
                    loading: false,
                    showsuccess: false,
                })
            });
    }

    getRecipientCompletion(search) {
        axios.get(`${process.env.REACT_APP_API_URL}/orderautocomplete`, {
            auth: {
                username: localStorage.getItem('username') || "",
                password: localStorage.getItem('password') || ""
            },
            params: {
                s: search
            }
        }).then((response) => {
            //console.log("response.data.result", response.data.result);

            this.setState({
                recipientResults: response.data.result,
                loading: false
            })

        });
    }

    getAddressCompletionStructed(city, zipcode, street, country) {
        let { formData } = this.state;

        const search = {
            country: country,
            city: city,
            zipcode: zipcode,
            street: street
        }
        axios.get(`${process.env.REACT_APP_API_URL}/addresscompletion`, {
            auth: {
                username: localStorage.getItem('username') || "",
                password: localStorage.getItem('password') || ""
            },
            params: {
                json: search
            }
        }).then((response) => {
            //console.log("response.data.result", response.data.result);
            if (response.data.result && response.data.result.length > 0) {
                formData.recipient_latitude = response.data.result[0].latitude;
                formData.recipient_longitude = response.data.result[0].longitude;
                this.setState({
                    formData: formData,
                    loading: false
                });
                this.getStockItemsByNearestNeighbor(formData.recipient_country, formData.recipient_latitude, formData.recipient_longitude);
            }
        });
    }

    getAddressCompletion(search) {
        let query = `${process.env.REACT_APP_API_URL}/addresscompletion`;
        axios.get(`${query}`, {
            auth: {
                username: localStorage.getItem('username') || "",
                password: localStorage.getItem('password') || ""
            },
            params: {
                s: search
            }
        }).then((response) => {
            //console.log("response.data.result", response.data.result);
            this.setState({
                addressResults: response.data.result,
                addressRequestCompleted: true,
                loading: false
            })
        });
    }

    getDetailAddressInformation(address) {
        //console.log("addressaddress", address);
        let { formData } = this.state;
        formData.recipient_address = address.street + " " + address.housenumber;
        formData.recipient_city = address.city;
        formData.recipient_zipcode = address.zipcode;
        formData.recipient_country = address.country;
        formData.recipient_latitude = address.latitude;
        formData.recipient_longitude = address.longitude;
        //setTimeout(() => {
        this.setState({
            //addressResults: response.data.result,
            formData: formData
        })
        //}, 3000)


        this.getStockItemsByNearestNeighbor(formData.recipient_country, formData.recipient_latitude, formData.recipient_longitude);
    }

    getStockItemsByNearestNeighbor(country, latitude, longitude) {
        this.setState({
            loading: true,
        })
        let path = "stockitems/nearestneighbor/" + this.distanceCalculation;
        axios.get(`${process.env.REACT_APP_API_URL}/${path}/${country}/${latitude}/${longitude}`, {
            auth: {
                username: localStorage.getItem('username') || "",
                password: localStorage.getItem('password') || ""
            }
        }).then((response) => {
            //console.log("response.data.result", response.data.result);
            this.setState({
                stockItems: response.data.result,
                loading: false
            })

        });
    }

    getArticle() {
        this.setState({
            loading: true,
        })
        axios.get(`${process.env.REACT_APP_API_URL}/articles.distinct2`, {
            auth: {
                username: localStorage.getItem('username') || "",
                password: localStorage.getItem('password') || ""
            }
        }).then((response) => {
            //console.log("response.data.result", response.data.result);
            this.setState({
                articles: response.data.result,
                loading: false
            })
        });
    }

    getCustomers() {
        this.setState({
            loading: true,
        })
        axios.get(`${process.env.REACT_APP_API_URL}/customers`, {
            auth: {
                username: localStorage.getItem('username') || "",
                password: localStorage.getItem('password') || ""
            }
        }).then((response) => {
            //console.log("getCustomers, response.data.result", response.data.result);
            this.setState({
                customers: response.data.result,
                loading: false
            })
        });
    }


    getCustomerRelation() {
        this.setState({
            loading: true,
        })
        axios.get(`${process.env.REACT_APP_API_URL}/customerRelation`, {
            auth: {
                username: localStorage.getItem('username') || "",
                password: localStorage.getItem('password') || ""
            }
        }).then((response) => {
            if (response.data.status == 200 && response.data.result.length > 0) {
                //console.log("getCustomerRelation, response.data.result", response.data.result);
                setTimeout(() => {
                    this.setState({
                        selectedCustomer: response.data.result[0]
                    })
                }, 500)
            }
        });
    }

    updateCustomerRelation(selectedCustomer) {
        this.setState({
            loading: true,
        })
        axios({
            method: "PUT",
            url: `${process.env.REACT_APP_API_URL}/customerRelation`,
            data: selectedCustomer,
            auth: {
                username: localStorage.getItem('username') || "",
                password: localStorage.getItem('password') || ""
            }
        })
            .then((response) => {
                //console.log(response.data);
                this.setState({
                    loading: false,
                })
            })
            .catch(function (error) {
                console.log(error);
                this.setState({
                    loading: false
                })
            });
    }

    buildProductJSX(i) {
        const { selectedArticle, articles, error, deactiveInput } = this.state;

        let lessButton;

        if (i > 0) {
            lessButton = <Button variant="contained" color="secondary" onClick={this.handleOnLessProductsClicked}><RemoveIcon id={"less_" + i} /></Button>;
        }
        return (
            <div>
                <Grid container spacing={3}>
                    <Grid item xs={10}>
                        <FormControl fullWidth>
                            <InputLabel>Article *</InputLabel>
                            <Select
                                name={"articleList-" + i}
                                onChange={this.onArticleChange}
                                label="Article"
                                value={selectedArticle[i]}
                                required
                                disabled={deactiveInput}
                                error={error.artice}
                            >
                                <MenuItem key={0} value={0}>Please choose</MenuItem>
                                {articles.map((row) => (
                                    <MenuItem key={row.id} value={row.id}>{row.label}</MenuItem>
                                ))}
                            </Select>
                        </FormControl>
                    </Grid>
                    <Grid item xs={1}>
                        {lessButton}
                    </Grid>
                    <Grid item xs={1}>
                        <Button variant="contained" onClick={this.handleOnMoreProductsClicked}><AddIcon id={"more_" + i} /></Button>
                    </Grid>
                </Grid>
                <br />
            </div>
        )

    }

    renderProductJSX() {
        const { selectedArticle } = this.state;
        let content = [];
        for (let key in selectedArticle) {
            content.push(this.buildProductJSX(key));
        }

        if (content.length == 0) {
            content.push(this.buildProductJSX(0));
        }

        return content;
    }

    render() {
        const { classes } = this.props;
        const { loading, formData, addressResults, recipientResults, stockItemByNearestDistance,
            showerror, showsuccess, saveButtonName, addressSearch, customers, error, selectedCustomer, deactiveInput
        } = this.state;
        let content;
        let submitInfo;
        let loadingIndicator = "";
        if (loading) {
            loadingIndicator = <CircularProgress />;
        }

        if (showsuccess) {
            submitInfo = <Alert severity="success">Der Auftrag wurde erfolgreich übermittelt und befindet sich in Ihrer Historie.</Alert>;
        }

        if (showerror) {
            submitInfo = <Alert severity="error">Es ist zu einem Fehler bei der Übertragung des Auftrages gekommen.</Alert>;
        }


        return (
            <div className={classes.root}>
                {loadingIndicator}
                {submitInfo}
                <React.Fragment>
                    <Grid container spacing={3}>
                        <Grid item xs={10}>
                            <form onSubmit={this.handleSubmit} >
                                <h2>Auftragsdaten</h2>
                                {this.user.role != "Customer" &&
                                    <Stack spacing={2} direction="row" sx={{ marginBottom: 4 }}>
                                        <FormControl fullWidth>
                                            <InputLabel>Auftraggeber *</InputLabel>
                                            <Select
                                                name="customer"
                                                onChange={this.onCustomerChange}
                                                label="Customer"
                                                value={selectedCustomer.id ? selectedCustomer.id : ""}
                                                required
                                                disabled={deactiveInput}
                                            >
                                                {customers.map((row) => (
                                                    <MenuItem key={row.id} value={row.id}>{row.name + ", " + row.city}</MenuItem>
                                                ))}
                                            </Select>
                                        </FormControl>
                                    </Stack>
                                }
                                <Stack spacing={2} direction="row" sx={{ marginBottom: 4 }}>
                                    <Autocomplete
                                        freeSolo
                                        fullWidth
                                        disableClearable
                                        onInputChange={this.handleOnChangeRecipient}
                                        onChange={this.handleOnRecipientSelected}
                                        options={recipientResults.map((option) => option.recipient_company)}
                                        disabled={deactiveInput}
                                        renderInput={(params) => (
                                            <TextField
                                                {...params}
                                                variant="filled"
                                                label="Search previous Consignees"
                                                error={error.recipient_company}
                                                InputProps={{
                                                    ...params.InputProps,
                                                    type: 'search',
                                                }}
                                            />
                                        )}
                                    />
                                </Stack>
                                <Stack spacing={2} direction="row" sx={{ marginBottom: 4 }}>
                                    <TextField
                                        type="text"
                                        id="recipient_company"
                                        label="Company Name"
                                        required
                                        error={error.recipient_company}
                                        onChange={this.handleOnChange}
                                        value={formData.recipient_company}
                                        disabled={deactiveInput}
                                        inputProps={{ maxLength: 40 }}
                                        fullWidth
                                    />
                                    <TextField
                                        type="text"
                                        id="recipient_name"
                                        label="Contact Person"
                                        onChange={this.handleOnChange}
                                        value={formData.recipient_name}
                                        disabled={deactiveInput}
                                        inputProps={{ maxLength: 40 }}
                                        fullWidth
                                    />
                                    <TextField
                                        type="text"
                                        id="recipient_phone"
                                        label="Phone"
                                        onChange={this.handleOnChange}
                                        value={formData.recipient_phone}
                                        disabled={deactiveInput}
                                        autoComplete='off'
                                        fullWidth
                                    />
                                </Stack>
                                <Stack spacing={2} direction="row" sx={{ marginBottom: 4 }}>
                                    <Autocomplete
                                        id="address_search"
                                        freeSolo
                                        fullWidth
                                        required
                                        autoComplete
                                        includeInputInList
                                        filterSelectedOption
                                        value={addressSearch}
                                        onInputChange={this.handleOnChangeAddress}
                                        onChange={this.handleOnAddressSelected}
                                        filterOptions={(x) => x}
                                        disabled={deactiveInput}
                                        options={addressResults.map((option) => option.pattern)}
                                        disableClearable
                                        renderInput={(params) => (
                                            <TextField
                                                {...params}
                                                variant="filled"
                                                label="Search for Address only"
                                                InputProps={{
                                                    ...params.InputProps,
                                                    type: 'search',
                                                }}
                                            />
                                        )}
                                    />
                                </Stack>
                                <Stack spacing={2} direction="row">
                                    <TextField
                                        type="text"
                                        id="recipient_address"
                                        name="recipient_address"
                                        label="Street and Housenumber"
                                        onChange={this.handleOnChange}
                                        value={formData.recipient_address}
                                        disabled={deactiveInput}
                                        fullWidth
                                        required
                                        error={error.recipient_address}
                                        sx={{ mb: 4 }}
                                    />

                                </Stack>
                                <Stack spacing={2} direction="row" sx={{ marginBottom: 4 }}>
                                    <TextField
                                        id="recipient_country"
                                        label="2-Letter-ISO-Country Code"
                                        onChange={this.handleOnChange}
                                        value={formData.recipient_country}
                                        inputProps={{ maxLength: 2, style: { textTransform: "uppercase" } }}
                                        disabled={deactiveInput}
                                        fullWidth
                                        required
                                        error={error.recipient_country}
                                    />
                                    <TextField
                                        id="recipient_zipcode"
                                        label="ZIP"
                                        onChange={this.handleOnChange}
                                        value={formData.recipient_zipcode}
                                        disabled={deactiveInput}
                                        fullWidth
                                        required
                                        error={error.recipient_zipcode}
                                    />
                                    <TextField
                                        id="recipient_city"
                                        label="Town"
                                        onChange={this.handleOnChange}
                                        value={formData.recipient_city}
                                        disabled={deactiveInput}
                                        fullWidth
                                        required
                                        error={error.recipient_city}
                                    />
                                </Stack>
                                <Stack spacing={2} direction="row" sx={{ marginBottom: 4 }}>
                                    <TextField
                                        type="text"
                                        id="additional_infos"
                                        name="additional_infos"
                                        label="Additional Information"
                                        onChange={this.handleOnChange}
                                        value={formData.additional_infos}
                                        inputProps={{ maxLength: 100 }}
                                        disabled={deactiveInput}
                                        fullWidth
                                    />
                                    <TextField
                                        type="text"
                                        id="customer_reference"
                                        name="customer_reference"
                                        label="Customer Reference"
                                        onChange={this.handleOnChange}
                                        value={formData.customer_reference}
                                        inputProps={{ maxLength: 16 }}
                                        disabled={deactiveInput}
                                        autoComplete='off'
                                        fullWidth
                                    />
                                </Stack>
                                <h2>Produktdaten</h2>
                                {this.renderProductJSX()}
                                <h2>Service</h2>
                                <Stack spacing={2} direction="row" >
                                    <Select
                                        name="service"
                                        onChange={this.onServiceChange}
                                        label="Service"
                                        value={formData.service}
                                        required
                                        disabled={deactiveInput}
                                        fullWidth
                                        error={error.artice}
                                    >
                                        <MenuItem value="DI">DI - Direct Drive</MenuItem>
                                        <MenuItem value="ON">ON - Overnight</MenuItem>
                                    </Select>
                                </Stack>
                                <br />
                                <Grid container spacing={3}>
                                    <Grid item xs={12}>
                                        <Button
                                            variant="outlined"
                                            color="secondary"
                                            //disabled={this.user.role == "Supervisor" || deactiveInput ? true : false}
                                            type="submit"
                                            size="large"
                                            endIcon={<SendIcon />}
                                        >{saveButtonName}
                                        </Button>
                                    </Grid>
                                </Grid>
                            </form>
                        </Grid>
                        <Grid item xs={2}>
                            <h2>&nbsp;</h2>
                            <Card sx={{ minWidth: 180 }}>
                                <CardContent>
                                    <Typography sx={{ mb: 1.5 }} color="text.secondary">
                                        {stockItemByNearestDistance && stockItemByNearestDistance.length > 0 ? `Station: ` + stockItemByNearestDistance[0].acronym : ""}
                                    </Typography>
                                    <Typography variant="body2" style={{ lineHeight: "24px" }}>
                                        {stockItemByNearestDistance && stockItemByNearestDistance.length > 0 ? `Für: ` + stockItemByNearestDistance[0].label : ""}<br />
                                        {stockItemByNearestDistance && stockItemByNearestDistance.length > 0 && stockItemByNearestDistance[0].distance ? `Distanz: ` + stockItemByNearestDistance[0].distance.toFixed(0) + " km" : ""}<br />
                                        {stockItemByNearestDistance && stockItemByNearestDistance.length > 0 && stockItemByNearestDistance[0].eta ? `ETA: ` + moment(stockItemByNearestDistance[0].eta).format("DD.MM.YYYY  HH:mm") : ""}
                                    </Typography>
                                </CardContent>
                            </Card>
                            {/*
                            <h2>&nbsp;</h2>
                            <Card sx={{ minWidth: 275 }}>
                                <CardContent>
                                    <Typography sx={{ mb: 1.5 }} color="text.secondary">
                                        {stockItemByNearestDistance && stockItemByNearestDistance.length > 0 ? stockItemByNearestDistance[0].latitude + ", " + stockItemByNearestDistance[0].longitude : "0, 0"}
                                    </Typography>
                                    <Typography sx={{ mb: 1.5 }} color="text.secondary">
                                        {formData.recipient_latitude + ", " + formData.recipient_longitude}
                                    </Typography>
                                </CardContent>
                            </Card>
                            */}
                        </Grid>
                    </Grid>
                </React.Fragment>
                {submitInfo}
            </div>
        );
    }
}

export default withStyles(useStyles)(Order)