import { withStyles } from '@mui/styles';
import React from 'react';
import CircularProgress from '@mui/material/CircularProgress';


const useStyles = (theme) => ({
  root: {
    
  },
  table: {
    minWidth: 650,
  },
});


class Reload extends React.Component {

    constructor(props) {
        super(props);
        this.state = {
            
            loading: true
        };
        
    }
    
    render(){
        const { classes } = this.props;
        const { loading } = this.state;        
        let loadingIndicator = "";
        if(loading){
            loadingIndicator = <CircularProgress />;
        }

        return (
            <div className={classes.root}>  
                {loadingIndicator}                
            </div>
        );
    }
}

export default withStyles(useStyles)(Reload)