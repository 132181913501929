import { withStyles } from '@mui/styles';
import React from 'react';
import { Link } from '@mui/material';
import Divider from '@mui/material/Divider';

import Accordion from '@mui/material/Accordion';
import AccordionSummary from '@mui/material/AccordionSummary';
import AccordionDetails from '@mui/material/AccordionDetails';
import ExpandMoreIcon from '@mui/icons-material/ExpandMore';

import orderreception from '../assets/orderreception.gif';
import TaskIcon from '@mui/icons-material/Task';




const useStyles = (theme) => ({
    root: {

    },
    table: {
        minWidth: 650,
    },
});


class Upload extends React.Component {

    constructor(props) {
        super(props);
        this.state = {
        };
    }

    render() {
        const { classes } = this.props;
        const { message, openAlert, uploadStatus } = this.state;

        return (
            <div className={classes.root}>
                <h3>User Manual</h3>
                <Divider />
                <Accordion>
                    <AccordionSummary
                        expandIcon={<ExpandMoreIcon />}
                        aria-controls="panel1-content"
                        id="panel1-header"
                    >
                        <TaskIcon />&nbsp;&nbsp;&nbsp;Order
                    </AccordionSummary>
                    <AccordionDetails>
                        <Divider />
                        <br />
                        <img src={orderreception} align={"middle"} width={"85%"} height={"85%"} alt="Order Reception" />
                        <br />
                    </AccordionDetails>
                </Accordion>
                <Accordion>
                    <AccordionSummary
                        expandIcon={<ExpandMoreIcon />}
                        aria-controls="panel2-content"
                        id="panel2-header"
                    >
                        Accordion 2
                    </AccordionSummary>
                    <AccordionDetails>
                        Lorem ipsum dolor sit amet, consectetur adipiscing elit. Suspendisse
                        malesuada lacus ex, sit amet blandit leo lobortis eget.
                    </AccordionDetails>
                </Accordion>
            </div>
        );
    }
}

export default withStyles(useStyles)(Upload)