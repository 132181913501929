import { withStyles } from '@mui/styles';
import React from 'react';
import axios from 'axios';
import clsx from 'clsx';

import List from '@mui/material/List';
import IconButton from '@mui/material/IconButton';
import ListItem from '@mui/material/ListItem';
import ListSubheader from '@mui/material/ListSubheader';
import ListItemText from '@mui/material/ListItemText';

import ListItemSecondaryAction from '@mui/material/ListItemSecondaryAction';

import Button from '@mui/material/Button';
import DeleteIcon from '@mui/icons-material/Delete';
import EditIcon from '@mui/icons-material/Edit';
import Grid from '@mui/material/Grid';
import TextField from '@mui/material/TextField';
import CircularProgress from '@mui/material/CircularProgress';
import Autocomplete from '@mui/material/Autocomplete';
import FormControlLabel from '@mui/material/FormControlLabel';
import Checkbox from '@mui/material/Checkbox';

import FormControl from '@mui/material/FormControl';
import RemoveIcon from '@mui/icons-material/Remove';
import { InputLabel } from '@mui/material';
import Select from '@mui/material/Select';
import MenuItem from '@mui/material/MenuItem';
import AddIcon from '@mui/icons-material/Add';

import Paper from '@mui/material/Paper';
import Table from '@mui/material/Table';
import TableBody from '@mui/material/TableBody';
import TableCell from '@mui/material/TableCell';
import TableContainer from '@mui/material/TableContainer';
import TableHead from '@mui/material/TableHead';
import TableRow from '@mui/material/TableRow';
import Link from '@mui/material/Link';
import MapIcon from '@mui/icons-material/Map';

const useStyles = (theme) => ({
    root: {

    },
    textField: {
        width: '100ch',
    },
});


class Stations extends React.Component {

    constructor(props) {
        super(props);
        this.user = JSON.parse(localStorage.getItem("user"));
        this.state = {
            stations: [],
            editOrAdd: false,
            station: {
                id: null,
                acronym: "",
                name: "",
                email: "",
                address: "",
                country: "",
                city: "",
                zipcode: "",
                storage_place_ismandatory: false,
                latitude: 0.0,
                longitude: 0.0
            },
            loading: false,
            addressSearch: "",
            addressResults: [],
            addressRequestCompleted: true,
            countryCount: 1,
            countries: [],
            selectedCountry: {},
        };

    }
    componentDidMount() {
        this.getStations();
        this.getCountries();
    }

    handleOnAddClick = (e) => {
        this.setState({
            editOrAdd: true,
            station: {
                id: null
            }
        })
    }

    handleOnEditClick = (e, station) => {
        //console.log("station", station);
        let selectedCountry = {};
        for (let i = 0; i < station.countriesBlacklist.length; i++) {
            selectedCountry[i] = station.countriesBlacklist[i];
        }
        this.setState({
            editOrAdd: true,
            station: station,
            selectedCountry: selectedCountry,
            countryCount: Object.keys(selectedCountry).length > 0 ? Object.keys(selectedCountry).length : 1
        })
    }

    handleOnBackClick = (e) => {
        this.setState({
            editOrAdd: false,
            station: {
                id: null
            }
        })
    }

    handleOnSaveClick = (e) => {
        const { station } = this.state;
        //console.log("station", station);
        this.setState({
            loading: true
        })
        let method = "post";
        if (station.id != null) {
            method = "put";
        }
        this.updateStation(method, station);
    }

    handleOnStationValue = (e) => {
        let { station } = this.state;
        station[e.target.id] = e.target.value || "";
        this.setState({
            station: station
        });
    }

    handleOnStorageLocation = (e) => {
        let { station } = this.state;
        station[e.target.id] = e.target.checked || "";

        this.setState({
            station: station
        });
    }

    handleOnChangeAddress = (e, newinput) => {
        let { addressRequestCompleted } = this.state;
        //console.log(addressRequestCompleted, newinput);
        if (newinput && newinput.length > 0 && addressRequestCompleted) {
            this.setState({
                addressRequestCompleted: false
            })
            this.getAddressCompletion(newinput);
        }
    }

    handleOnAddressSelected = (e, newinput) => {
        const { addressResults } = this.state;
        let index = e.target;
        //console.log(e.target); 
        //console.log("addressResults", addressResults);
        if (addressResults && addressResults.length > 0) {
            this.getDetailAddressInformation(addressResults[0].placeId)
        }
    }

    getCountries() {
        axios.get(`${process.env.REACT_APP_API_URL}/countries`, {
            auth: {
                username: localStorage.getItem('username') || "",
                password: localStorage.getItem('password') || ""
            }
        }).then((response) => {
            //console.log("response.data.result", response.data.result);
            this.setState({
                countries: response.data.result,
                loading: false
            })
        });
    }

    getAddressCompletion(search) {
        axios.get(`${process.env.REACT_APP_API_URL}/addresscompletion/` + search, {
            auth: {
                username: localStorage.getItem('username') || "",
                password: localStorage.getItem('password') || ""
            }
        }).then((response) => {
            //console.log("response.data.result", response.data.result);
            this.setState({
                addressResults: response.data.result,
                addressRequestCompleted: true,
                loading: false
            })
        });
    }

    getDetailAddressInformation(placeId) {
        //console.log("getDetailAddressInformation", placeId);
        axios.get(`${process.env.REACT_APP_API_URL}/addressdetails/` + placeId, {
            auth: {
                username: localStorage.getItem('username') || "",
                password: localStorage.getItem('password') || ""
            }
        }).then((response) => {
            if (response && response.data.result && response.data.result.placeId) {
                //console.log("getDetailAddressInformation", response.data.result);
                let { station } = this.state;
                station.address = response.data.result.street + " " + response.data.result.streetNumber;
                station.city = response.data.result.city;
                station.zipcode = response.data.result.zipcode;
                station.latitude = response.data.result.latitude;
                station.longitude = response.data.result.longitude;
                this.setState({
                    //addressResults: response.data.result,
                    station: station
                })
            }
        });
    }

    getStations() {
        axios.get(`${process.env.REACT_APP_API_URL}/stations`, {
            auth: {
                username: localStorage.getItem('username') || "",
                password: localStorage.getItem('password') || ""
            }
        }).then((response) => {
            this.setState({
                stations: response.data.result
            })
        });
    }

    updateStation(method, station) {
        const { selectedCountry } = this.state;
        station.countriesBlacklist = selectedCountry;

        axios({
            method: method,
            url: `${process.env.REACT_APP_API_URL}/station`,
            data: station,
            auth: {
                username: localStorage.getItem('username') || "",
                password: localStorage.getItem('password') || ""
            }
        })
            .then((response) => {
                //console.log(response);
                setTimeout(() => {
                    this.getStations();
                    this.setState({
                        editOrAdd: false,
                        loading: false,
                        station: {
                            id: null
                        }
                    });
                }, 500);


            })
            .catch(function (error) {
                console.log(error);
            });

    }

    handleOnMoreCountriesClicked = (e) => {
        let { countryCount } = this.state;
        countryCount++;
        this.setState({
            countryCount: countryCount
        })
    }

    handleOnLessCountriesClicked = (e) => {
        let { countryCount, selectedCountry } = this.state;
        countryCount--;
        let newSelectedCountries = {};
        for (let i = 0; i < countryCount; i++) {
            newSelectedCountries[i] = selectedCountry[i];
        }
        this.setState({
            countryCount: countryCount,
            selectedCountry: newSelectedCountries
        })
    }

    onCountryChange = (e) => {
        let { selectedCountry } = this.state;
        let i = e.target.name.split("-")[1];
        let id = e.target.value;
        selectedCountry[i] = id;
        this.setState({
            selectedCountry: selectedCountry
        });

    }

    buildCountryJSX(i) {
        const { countries, selectedCountry } = this.state;
        let lessButton;

        if (i > 0) {
            lessButton = <Button variant="contained" color="secondary" onClick={this.handleOnLessCountriesClicked}><RemoveIcon /></Button>;
        }
        return (
            <Grid container spacing={3} sx={{ marginBottom: 4 }}>
                <Grid item xs={10}>
                    <FormControl fullWidth>
                        <InputLabel>Country</InputLabel>
                        <Select
                            name={"countryList-" + i}
                            onChange={this.onCountryChange}
                            label="Country"
                            value={selectedCountry[i]}
                        >
                            <MenuItem value="">
                                <em>None</em>
                            </MenuItem>
                            {countries.map((row) => (
                                <MenuItem key={row.isocode} value={row.isocode}>{row.isocode + " " + row.name}</MenuItem>
                            ))}
                        </Select>
                    </FormControl>
                </Grid>
                <Grid item xs={1}>
                    {lessButton}
                </Grid>
                <Grid item xs={1}>
                    <Button variant="contained" onClick={this.handleOnMoreCountriesClicked}><AddIcon /></Button>
                </Grid>
            </Grid>
        )

    }

    renderCountryJSX() {
        const { countryCount } = this.state;
        let content = [];
        for (let i = 0; i < countryCount; i++) {
            content.push(this.buildCountryJSX(i));
        }
        return content;
    }


    render() {
        const { classes } = this.props;
        const { stations, editOrAdd, station, loading, addressSearch, addressResults } = this.state;
/*
        let listItems = [];
        if (stations) {
            for (let i = 0; i < stations.length; i++) {
                let tag = stations[i].acronym
                let name = stations[i].name;
                let sc = stations[i].address + "\n" + stations[i].country + " " + stations[i].zipcode + " " + stations[i].city;
                sc += "\nlast inventory: " + stations[i].inventory_date;

                listItems.push(
                    <ListItem key={stations[i].id}>
                        <ListItemText
                            primary={tag}
                        />
                        <ListItemText
                            primary={name}
                        />
                        <ListItemText
                            secondary={sc}
                        />
                        <ListItemSecondaryAction>
                            <IconButton edge="end" aria-label="edit" onClick={((e) => this.handleOnEditClick(e, stations[i]))} >
                                <EditIcon />
                            </IconButton>
                        </ListItemSecondaryAction>
                    </ListItem>
                )

            }
        }
*/
        let content;
        let loadingIndicator = "";
        if (loading) {
            loadingIndicator = <CircularProgress />;
        }

        if (!editOrAdd) {
            content =
                <div>
                    <Grid container spacing={3}>
                        <Grid item xs={10}>
                            <TableContainer component={Paper}>
                                <Table stickyHeader className={classes.table} aria-label="ArticleList">
                                    <TableHead>
                                        <TableRow>
                                            <TableCell>ID</TableCell>
                                            <TableCell>Name</TableCell>
                                            <TableCell>Address</TableCell>
                                            <TableCell>Map</TableCell>
                                            <TableCell>Edit</TableCell>
                                        </TableRow>
                                    </TableHead>
                                    <TableBody>
                                        {stations.map((row) => (
                                            <TableRow key={row.id}>
                                                <TableCell component="th" scope="row">
                                                    <strong>{row.acronym}</strong>
                                                </TableCell>
                                                <TableCell >{row.name}</TableCell>
                                                <TableCell >{row.address}<br />{row.country} {row.zipcode} {row.city}</TableCell>
                                                <TableCell >
                                                    <div>
                                                        <Link target="_blank" href={"http://www.openstreetmap.org/?mlat=" + row.latitude + "&mlon=" + row.longitude + "&zoom=16"}>
                                                            <IconButton edge="end" aria-label="map" >
                                                                <MapIcon style={{ color: '#940234' }} />
                                                            </IconButton>
                                                        </Link>
                                                    </div>
                                                </TableCell>
                                                <TableCell>
                                                    <IconButton edge="end" aria-label="edit" onClick={((e) => this.handleOnEditClick(e, row))} >
                                                        <EditIcon />
                                                    </IconButton>
                                                </TableCell>
                                            </TableRow>
                                        ))}
                                    </TableBody>
                                </Table>
                            </TableContainer>
                        </Grid>
                        <Grid item xs={2}>
                            <Button variant="contained" onClick={this.handleOnAddClick} >Add Station</Button>
                        </Grid>
                    </Grid>

                </div >;
        }
        else {
            content =
                <form className={classes.root} noValidate autoComplete="off">
                    <Grid container spacing={3}>
                        <h2>Station</h2>
                        <Grid container spacing={3}>
                            <Grid item xs={4}>
                                <TextField
                                    id="acronym"
                                    label="Acronym"
                                    fullWidth
                                    onChange={this.handleOnStationValue}
                                    value={station.acronym}
                                />
                            </Grid>
                            <Grid item xs={8}>
                                <TextField
                                    id="name"
                                    label="Name"
                                    fullWidth
                                    onChange={this.handleOnStationValue}
                                    value={station.name}
                                />
                            </Grid>

                            <Grid item xs={12}>
                                <TextField
                                    id="email"
                                    label="E-Mail (comma separated for multiple recipients)"
                                    placeholder="E-Mail"
                                    fullWidth
                                    onChange={this.handleOnStationValue}
                                    value={station.email}
                                />
                            </Grid>

                            <Grid item xs={12}>
                                <Autocomplete
                                    id="address_search"
                                    freeSolo
                                    fullWidth
                                    required
                                    autoComplete
                                    includeInputInList
                                    filterSelectedOption
                                    value={addressSearch}
                                    onInputChange={this.handleOnChangeAddress}
                                    onChange={this.handleOnAddressSelected}
                                    filterOptions={(x) => x}
                                    options={addressResults.map((option) => option.pattern)}
                                    disableClearable
                                    renderInput={(params) => (
                                        <TextField
                                            {...params}
                                            label="Adress-Suche"
                                            InputProps={{
                                                ...params.InputProps,
                                                type: 'search',
                                            }}
                                        />
                                    )}
                                />
                            </Grid>
                            <Grid item xs={12}>
                                <TextField
                                    id="address"
                                    label="Street"
                                    fullWidth
                                    onChange={this.handleOnStationValue}
                                    value={station.address}
                                />
                            </Grid>

                            <Grid item xs={3}>
                                <TextField
                                    id="country"
                                    label="Country"
                                    inputProps={{ maxLength: 2, style: { textTransform: "uppercase" } }}
                                    fullWidth
                                    onChange={this.handleOnStationValue}
                                    value={station.country}
                                />
                            </Grid>
                            <Grid item xs={3}>
                                <TextField
                                    id="zipcode"
                                    label="Zipcode"
                                    fullWidth
                                    onChange={this.handleOnStationValue}
                                    value={station.zipcode}
                                />
                            </Grid>
                            <Grid item xs={6}>
                                <TextField
                                    id="city"
                                    label="City"
                                    fullWidth
                                    onChange={this.handleOnStationValue}
                                    value={station.city}
                                />
                            </Grid>
                            <Grid item xs={4}>
                                <TextField
                                    id="latitude"
                                    label="Latitude"
                                    fullWidth
                                    onChange={this.handleOnStationValue}
                                    value={station.latitude}
                                />
                            </Grid>
                            <Grid item xs={4}>
                                <TextField
                                    id="longitude"
                                    label="Longitude"
                                    fullWidth
                                    onChange={this.handleOnStationValue}
                                    value={station.longitude}
                                />
                            </Grid>
                            <Grid item xs={3}>
                                <FormControlLabel control={
                                    <Checkbox
                                        id="storage_place_ismandatory"
                                        label="storage_place_ismandatory"
                                        onClick={this.handleOnStorageLocation}
                                        value={station.storage_place_ismandatory}
                                        checked={station.storage_place_ismandatory == true ? 1 : 0}
                                    />
                                } label="Storage Location mandatory" />
                            </Grid>
                        </Grid>
                        <h2>Countries Blacklist</h2>
                        {this.renderCountryJSX()}
                        <Grid item xs={2}>
                        </Grid>
                        <Grid item xs={4}>
                            <Button variant="contained" color="secondary" onClick={this.handleOnBackClick}>Back</Button>
                        </Grid>
                        <Grid item xs={2}>
                        </Grid>
                        <Grid item xs={4}>
                            <Button variant="contained" color="primary" onClick={this.handleOnSaveClick}>Save</Button>
                        </Grid>
                    </Grid>
                </form>
        }

        return (
            <div className={classes.root}>
                {loadingIndicator}
                {content}
            </div>
        );
    }
}

export default withStyles(useStyles)(Stations)