import { withStyles } from '@mui/styles';
import React from 'react';
import axios from 'axios';
import Table from '@mui/material/Table';
import TableBody from '@mui/material/TableBody';
import TableCell from '@mui/material/TableCell';
import TableContainer from '@mui/material/TableContainer';
import TableHead from '@mui/material/TableHead';
import TableRow from '@mui/material/TableRow';
import Paper from '@mui/material/Paper';
import CircularProgress from '@mui/material/CircularProgress';
import Grid from '@mui/material/Grid';
import MuiAlert from '@mui/material/Alert';

const Alert = React.forwardRef(function Alert(props, ref) {
    return <MuiAlert elevation={6} ref={ref} variant="filled" {...props} />;
});

const useStyles = (theme) => ({
    table: {
        minWidth: 450,
    },
});

class StockOverview extends React.Component {

    constructor(props) {
        super(props);
        this.user = JSON.parse(localStorage.getItem("user"));
        this.state = {
            articles: [],
            loading: false,
            currentUser: JSON.parse(localStorage.getItem("user"))
        };

    }

    componentDidMount() {
        this.getArticle();
    }

    componentDidUpdate() {
        //this.getArticle();
    }

    getArticle() {
        this.setState({
            loading: true,
        })
        axios.get(`${process.env.REACT_APP_API_URL}/articles.distinct`, {
            auth: {
                username: localStorage.getItem('username') || "",
                password: localStorage.getItem('password') || ""
            }
        }).then((response) => {
            //console.log("response.data.result", response.data.result);
            this.setState({
                articles: response.data.result,
                loading: false
            })
        });
    }

    render() {
        const { classes } = this.props;
        const { articles, loading } = this.state;

        let content;
        let loadingIndicator = "";
        if (loading) {
            loadingIndicator = <CircularProgress />;
        }

        if (articles && articles.length) {
            content =
                <div>
                    <Grid container spacing={3}>
                        <Grid item xs={12}>
                            <TableContainer component={Paper}>
                                <Table className={classes.table} aria-label="simple table">
                                    <TableHead>
                                        <TableRow>
                                            <TableCell>Name</TableCell>
                                            <TableCell>Station</TableCell>
                                            <TableCell>Artikel</TableCell>
                                            <TableCell>Box</TableCell>
                                            <TableCell>Count</TableCell>
                                        </TableRow>
                                    </TableHead>
                                    <TableBody>
                                        {articles.map((row, index) => (
                                            <TableRow key={index}>
                                                <TableCell ><strong>{row.name}</strong></TableCell>
                                                <TableCell >{row.acronym}</TableCell>
                                                <TableCell >{row.label}</TableCell>
                                                <TableCell >{row.box}</TableCell>
                                                <TableCell >{row.sum}</TableCell>
                                            </TableRow>
                                        ))}
                                    </TableBody>
                                </Table>
                            </TableContainer>
                        </Grid>
                    </Grid>
                </div>;
        }

        return (
            <div className={classes.root} >
                {loadingIndicator}
                {content}
            </div>
        );
    }
}

export default withStyles(useStyles)(StockOverview)