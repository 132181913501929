import { withStyles } from '@mui/styles';
import moment from "moment";
import React from 'react';
import axios from 'axios';
import Table from '@mui/material/Table';
import TableBody from '@mui/material/TableBody';
import TableCell from '@mui/material/TableCell';
import TableContainer from '@mui/material/TableContainer';
import TableHead from '@mui/material/TableHead';
import TableRow from '@mui/material/TableRow';
import Paper from '@mui/material/Paper';
import CircularProgress from '@mui/material/CircularProgress';
import Badge from '@mui/material/Badge';
import { styled } from '@mui/material/styles';
import IconButton from '@mui/material/IconButton';
import ShoppingCartIcon from '@mui/icons-material/ShoppingCart';
import Tooltip from '@mui/material/Tooltip';
import Button from '@mui/material/Button';

import Dialog from '@mui/material/Dialog';
import DialogActions from '@mui/material/DialogActions';
import DialogContent from '@mui/material/DialogContent';
import DialogContentText from '@mui/material/DialogContentText';
import DialogTitle from '@mui/material/DialogTitle';
import TextField from '@mui/material/TextField';
import Chip from '@mui/material/Chip';
import Stack from '@mui/material/Stack';
import ListItemText from '@mui/material/ListItemText';
import ListItem from '@mui/material/ListItem';
import List from '@mui/material/List';
import Divider from '@mui/material/Divider';
import AppBar from '@mui/material/AppBar';
import Toolbar from '@mui/material/Toolbar';
import Typography from '@mui/material/Typography';
import CloseIcon from '@mui/icons-material/Close';
import Slide from '@mui/material/Slide';
import Grid from '@mui/material/Grid';
import Link from '@mui/material/Link';
import Box from '@mui/material/Box';
import ArrowDownIcon from '@mui/icons-material/ArrowDownward';
import ExtensionIcon from '@mui/icons-material/Extension';

import MapIcon from '@mui/icons-material/Map';
import DirectionsIcon from '@mui/icons-material/Directions';
import TablePagination from '@mui/material/TablePagination';

import { DatePicker } from '@mui/x-date-pickers/DatePicker';
import { TimePicker } from '@mui/x-date-pickers/TimePicker';
import * as dayjs from 'dayjs'
import 'dayjs/locale/de'
import MuiAlert from '@mui/material/Alert';

const Alert = React.forwardRef(function Alert(props, ref) {
    return <MuiAlert elevation={6} ref={ref} variant="filled" {...props} />;
});



const StyledBadge = styled(Badge)(({ theme }) => ({
    '& .MuiBadge-badge': {
        right: -3,
        top: 13,
        border: `2px solid ${theme.palette.background.paper}`,
        padding: '0 4px',
    },
}));

const useStyles = (theme) => ({
    root: {

    },
    table: {
        minWidth: 650,
    },
});


const Transition = React.forwardRef(function Transition(props, ref) {
    return <Slide direction="up" ref={ref} {...props} />;
});

class OrderHistoryStation extends React.Component {

    constructor(props) {
        super(props);
        this.state = {
            orderhistory: [],
            selectedOrder: {},
            progressItem: {},
            shipmentNumber: "",
            serialNumber: "",
            serialNumberErrors: {},
            shipmentNumberError: false,
            openProgressDialog: false,
            openOrderDetailsDialog: false,
            loading: false,
            showError: false,
            errorMessage: "",
            processDialogAlert: "",
            formErrors: {
                weight: false,
                packages: false,
                pickup_date: false,
                pickup_time_from: false
            },
            serialNumberFieldsError: [],
            numPages: null,
            pageNumber: 1,
            page: 0,
            limit: 15,
            totalHistoryItems: 0,
        };

    }
    componentDidMount() {
        const { showDetails } = this.props;
        const {page, limit} = this.state;
        this.getOrderHistory(page, limit);
        if (showDetails) {
            setTimeout(() => {
                const { orderhistory } = this.state;
                const orderItem = orderhistory.filter((item) => item.id == showDetails);
                if (orderItem && orderItem.length > 0) {
                    this.setState({
                        openOrderDetailsDialog: true,
                        selectedOrder: orderItem[0]
                    })
                }

            }, 1500);
        }
    }

    onDetailClicked = (e) => {
        const { orderhistory } = this.state;
        e.preventDefault();
        if (e.target.name && e.target.name.length > 0) {
            const id = e.target.name;
            const orderItem = orderhistory.filter((item) => item.id == id);
            console.log("orderItem", orderItem)
            this.setState({
                openOrderDetailsDialog: true,
                selectedOrder: orderItem[0]
            })
        }
    }

    onChangeShipmentNumber = (e) => {
        this.setState(({
            shipmentNumber: e.target.value
        }))
    }

    onChangeDateItem = (e) => {
        let { progressItem } = this.state;
        progressItem.pickup_date = e.format("YYYY-MM-DD");
        this.setState(({
            progressItem: progressItem
        }))
    }

    onChangeTimeItem = (e) => {
        let { progressItem } = this.state;
        let from = e;
        let till = e.add(2, "hours")
        progressItem.pickup_time_from = from.format("HH:mm") + ":00";
        progressItem.pickup_time_till = till.format("HH:mm") + ":00";

        this.setState(({
            progressItem: progressItem
        }))

    }

    onChangeOrderItem = (e) => {
        let { progressItem } = this.state;
        progressItem[e.target.name] = e.target.value;

        this.setState(({
            progressItem: progressItem
        }))

    }

    handleCheckSerialNumber = (e) => {
        let { serialNumberErrors, progressItem } = this.state;
        const articleId = e.target.id.split("_")[1];
        const key = e.target.id.split("_")[2];
        const serialNumber = e.target.value;

        if (serialNumber.length > 2) {
            this.checkSerialNumber(articleId, serialNumber).then((response) => {
                if (response.data.result && response.data.result.length > 0) {
                    serialNumberErrors[key] = false;
                    progressItem.stock[key] = response.data.result[0];
                }
                else {
                    serialNumberErrors[key] = true;
                    delete progressItem.stock[key];
                }
                this.setState({
                    serialNumberErrors: serialNumberErrors,
                    progressItem: progressItem
                })
            });
        }

    }

    checkSerialNumber(articleId, serialNumber) {
        /*
        this.setState({            
            loading: true,
        })
        */
        return axios.get(`${process.env.REACT_APP_API_URL}/checkserial/${articleId}/${serialNumber}`, {
            auth: {
                username: localStorage.getItem('username') || "",
                password: localStorage.getItem('password') || ""
            }
        });
    }

    onProgressClicked = (e) => {
        e.preventDefault();
        let { orderhistory } = this.state;
        if (e.target.name && e.target.name.length > 0) {
            const id = e.target.name;
            let orderItem = orderhistory.filter((item) => item.id == id)[0];
            orderItem.stock = {};
            console.log("orderItem", orderItem);
            let serialNumberErrors = {};
            for (let i = 0; i < orderItem.articles.length; i++) {
                if (orderItem.articles[i].serialNumberMandatory) {
                    serialNumberErrors[i] = true;
                }
            }
            this.setState({
                openProgressDialog: true,
                progressItem: orderItem,
                serialNumberErrors: serialNumberErrors,
                processDialogAlert: ""
            })
        }
    }

    onProgressSaveClicked = (e) => {
        let { progressItem, formErrors, serialNumberErrors, processDialogAlert, selectedOrder } = this.state;
        console.log("progressItem", progressItem);
        const defaultPickupDay = moment();
        //console.log(defaultPickupDay);
        progressItem.pickup_date = defaultPickupDay.format("YYYY-MM-DD");
        progressItem.pickup_time_from = defaultPickupDay.format("HH:mm") + ":00";
        progressItem.pickup_time_till = defaultPickupDay.add(3, "hours").format("HH") + ":00"
        progressItem.user_id = progressItem.articles[0].user_id

        let errors = false;
        if (!progressItem.pickup_date || progressItem.pickup_date.length == 0) {
            formErrors.pickup_date = true;
            errors = true
        }
        else {
            formErrors.pickup_date = false;
        }

        if (!progressItem.pickup_time_from || progressItem.pickup_time_from.length == 0) {
            formErrors.pickup_time_from = true;
            errors = true
        }
        else {
            formErrors.pickup_time_from = false;
        }

        if (!progressItem.weight || progressItem.weight == "null") {
            let weight = 0;
            if (progressItem.articles && progressItem.articles.length > 0) {
                for (let i = 0; i < progressItem.articles.length; i++) {
                    weight += progressItem.articles[i].weight;
                }
            }
            progressItem.weight = weight;

        }
        else {
            formErrors.weight = false;
        }

        if (!progressItem.packages) {
            //formErrors.packages = true;
            //errors = true
            progressItem.packages = progressItem.articles.length;
        }
        else {
            formErrors.packages = false;
        }

        //console.log("serialNumberErrors lallala", serialNumberErrors);
        if (Object.keys(serialNumberErrors).length > 0) {
            let tempError = false;
            for (let key in serialNumberErrors) {
                if (serialNumberErrors[key]) {
                    tempError = true;
                    break;
                }
                else {
                    tempError = false;
                }
            }
            if (tempError) {
                processDialogAlert = "Die Seriennummer gibt es nicht";
                errors = true;
            }
            else {
                errors = false;
            }
        }


        this.setState({
            formErrors: formErrors,
            processDialogAlert: processDialogAlert
        })

        if (!errors) {
            progressItem.status_id = 2;
            this.setState({
                openProgressDialog: false
            });
            //console.log(progressItem);
            this.publishOrder(progressItem, 'PUT');
        }
    }

    publishOrder(orderdata, method) {
        this.setState({
            loading: true
        });
        const {page, limit} = this.state;
        //console.log("orderdata", orderdata, method);
        axios({
            method: method,
            url: `${process.env.REACT_APP_API_URL}/orderandclearstock`,
            data: orderdata,
            auth: {
                username: localStorage.getItem('username') || "",
                password: localStorage.getItem('password') || ""
            }
        })
            .then((response) => {
                //console.log("response", response.data);
                if (response.data.status == 400) {
                    this.setState({
                        loading: false,
                        showError: true,
                        errorMessage: response.data.message
                    })
                }

                this.getOrderHistory(page, limit);

            })
            .catch((error) => {
                console.log("error", error);
                this.setState({
                    loading: false,
                    showError: true
                })
            });
    }

    handleDetailsClose = () => {
        this.setState({
            openOrderDetailsDialog: false
        })
    }

    handleProgressClose = () => {
        this.setState({
            openProgressDialog: false
        })
    }

    handleChangePage = (event, newPage) => {
        //console.log("handleChangePage", newPage);
        const {limit} = this.state;
        
        this.setState({
            page: newPage
        })
        this.getOrderHistory(newPage, limit);
    }

    handleChangeRowsPerPage = (event) => {
        //console.log("handleChangeRowsPerPage", event.target.value);
        let {page, limit} = this.state;
        limit = event.target.value;
        this.setState({
            limit: limit
        });

        this.getOrderHistory(page, limit);
    };


    getOrderHistory(page, limit) {
        this.setState({
            loading: true,
        })

        const params = {
            page: page,
            limit: limit
        }

        axios.get(`${process.env.REACT_APP_API_URL}/orderhistory/station`, {
            auth: {
                username: localStorage.getItem('username') || "",
                password: localStorage.getItem('password') || ""
            },
            params: params
        }).then((response) => {
            //console.log("response.data.result", response.data.result);
            this.setState({
                orderhistory: response.data.result,
                totalHistoryItems: response.data.total,
                loading: false
            })
        });
    }

    onDocumentLoadSuccess = ({ numPages }) => {
        this.setState({ numPages });
    }

    render() {
        const { classes } = this.props;
        const { orderhistory, loading, openOrderDetailsDialog, openProgressDialog, selectedOrder, formErrors,
            showError, errorMessage, progressItem, pageNumber, numPages, serialNumberErrors, processDialogAlert, totalHistoryItems, page, limit } = this.state;
        let submitInfo;
        let processAlert;

        if (showError) {
            submitInfo = <Alert severity="error">Es ist zu einem Fehler bei der Übertragung des Auftrages gekommen: {errorMessage}</Alert>;
        }

        if (processDialogAlert && processDialogAlert.length > 0) {
            processAlert = <Alert severity="error">{processDialogAlert}</Alert>;
        }

        //const defaultPickupDay = moment();
        //console.log(defaultPickupDay);

        let content;
        let loadingIndicator = "";
        let tomorrow = new Date();
        tomorrow.setDate(tomorrow.getDate() + 1)
        if (loading) {
            loadingIndicator = <CircularProgress />;
        }
        if (orderhistory && orderhistory.length) {
            for (let i = 0; i < orderhistory.length; i++) {
                orderhistory[i].tooltip = "";
                for (let j = 0; j < orderhistory[i].articles.length; j++) {
                    orderhistory[i].tooltip += orderhistory[i].articles[j].count + " x " + orderhistory[i].articles[j].label + ' (' + (orderhistory[i].articles[j].serialnumber ? orderhistory[i].articles[j].serialnumber : "N/A") + ')\n';
                }
                orderhistory[i].tooltip += "";
            }
        }

        let pfdFile = process.env.REACT_APP_API_URL + "/orderlabel/" + selectedOrder.id;
        let weight = 0;
        if (progressItem.articles && progressItem.articles.length > 0) {
            for (let i = 0; i < progressItem.articles.length; i++) {
                weight += progressItem.articles[i].weight;
            }
        }

        //console.log("orderhistoryStation", orderhistory);
        if (orderhistory && orderhistory.length) {
            content =
                <div>
                    <TableContainer component={Paper}>
                        <Table stickyHeader className={classes.table} aria-label="Auftragshistorie">
                            <TableHead>
                                <TableRow>
                                    <TableCell>ID</TableCell>
                                    <TableCell >Customer</TableCell>
                                    <TableCell >Recipient</TableCell>
                                    <TableCell >Map</TableCell>
                                    <TableCell >Station</TableCell>
                                    <TableCell >Status</TableCell>
                                    <TableCell >Article</TableCell>
                                    <TableCell >Date</TableCell>
                                    <TableCell >Action</TableCell>
                                </TableRow>
                            </TableHead>
                            <TableBody>
                                {orderhistory.map((row) => (
                                    <TableRow key={row.id}>
                                        <TableCell component="th" scope="row">
                                            {row.id}
                                        </TableCell>
                                        <TableCell >{row.customer_name}</TableCell>
                                        <TableCell >{row.recipient_company}<br />{row.recipient_address}<br />{row.recipient_country} {row.recipient_zipcode} {row.recipient_city}</TableCell>
                                        <TableCell >
                                            <div>
                                                <Stack direction="row" spacing={1}>
                                                    <Link target="_blank" href={"https://routing.openstreetmap.de/?loc=" + row.station_latitude + "%2C" + row.station_longitude + "&loc=" + row.recipient_latitude + "%2C" + row.recipient_longitude + "&hl=de&srv=0&z=16"}>
                                                        <IconButton edge="end" aria-label="directions" >
                                                            <DirectionsIcon style={{ color: '#349402' }} />
                                                        </IconButton>
                                                    </Link>

                                                    <Link target="_blank" href={"https://www.openstreetmap.org/?mlat=" + row.recipient_latitude + "&mlon=" + row.recipient_longitude + "&zoom=16"}>
                                                        <IconButton edge="end" aria-label="map" >
                                                            <MapIcon style={{ color: '#940234' }} />
                                                        </IconButton>
                                                    </Link>
                                                </Stack>
                                            </div>

                                        </TableCell>
                                        <TableCell >{row.acronym}</TableCell>
                                        <TableCell >
                                            {row.label}
                                            {row.shipment_number && row.status_id >= 1 &&
                                                <div>
                                                    <Link target="_blank" href={process.env.REACT_APP_API_URL + "/orderlabel/" + row.id}>{row.shipment_number}</Link>
                                                </div>
                                            }
                                        </TableCell>
                                        <TableCell>
                                            <Tooltip
                                                title={
                                                    <div style={{ whiteSpace: 'pre-line' }}>{row.tooltip}</div>
                                                }
                                            >
                                                <IconButton aria-label="cart">
                                                    <StyledBadge badgeContent={row.articles.length} color="secondary">
                                                        <ShoppingCartIcon />
                                                    </StyledBadge>
                                                </IconButton>
                                            </Tooltip>
                                        </TableCell>
                                        <TableCell >{row.datetime}</TableCell>
                                        <TableCell >
                                            <Stack direction="row" spacing={2}>
                                                <Button variant="contained" color="secondary" disabled={row.status_id > 1} name={row.id} onClick={this.onProgressClicked}>
                                                    Progress
                                                </Button>
                                                <Button variant="contained" name={row.id} onClick={this.onDetailClicked}>
                                                    Details
                                                </Button>
                                            </Stack>
                                        </TableCell>

                                    </TableRow>
                                ))}
                            </TableBody>
                        </Table>
                    </TableContainer>
                    <TablePagination
                                rowsPerPageOptions={[15, 30, 60]}
                                component="div"
                                showFirstButton={true}
                                showLastButton={true}
                                count={totalHistoryItems}
                                rowsPerPage={limit}
                                page={page}
                                onPageChange={this.handleChangePage}
                                onRowsPerPageChange={this.handleChangeRowsPerPage}
                            />
                    {/*progress dialog*/}
                    <Dialog open={openProgressDialog} onClose={this.handleProgressClose}>
                        {processAlert}
                        <DialogTitle>Consignment packed?</DialogTitle>
                        <DialogContent>
                            <DialogContentText>
                                <br />
                            </DialogContentText>
                            <Grid container spacing={3}>
                                {/*
                                <Grid item xs={6}>
                                    <DatePicker
                                        label="Current Date"
                                        name="pickup_date"
                                        id="pickup_date"
                                        fullWidth
                                        required
                                        disabled={true}
                                        onChange={this.onChangeDateItem}
                                        error={formErrors.pickup_date}
                                        defaultValue={defaultPickupDay}
                                    />
                                </Grid>
                                <Grid item xs={6}>
                                    <TimePicker
                                        label="Current Time"
                                        name="pickup_time_from"
                                        id="pickup_time_from"
                                        fullWidth
                                        required
                                        disabled={true}
                                        onChange={this.onChangeTimeItem}
                                        error={formErrors.pickup_time_from}
                                        defaultValue={defaultPickupDay}
                                        views={['hours', 'minutes', 'seconds']}
                                    />
                                </Grid>
                                */}
                                <Grid item xs={6}>
                                    <TextField
                                        margin="dense"
                                        name="packages"
                                        id="packages"
                                        label="Packages"
                                        value={progressItem && progressItem.articles ? progressItem.articles.length : 0}
                                        type="number"
                                        fullWidth
                                        variant="outlined"
                                        onChange={this.onChangeOrderItem}
                                        error={formErrors.packages}
                                        InputProps={{ readOnly: true }}
                                    />
                                </Grid>
                                <Grid item xs={6}>
                                    <TextField
                                        margin="dense"
                                        name="weight"
                                        id="weight"
                                        label="Weight KG"
                                        type="number"
                                        fullWidth
                                        required
                                        value={weight}
                                        variant="outlined"
                                        onChange={this.onChangeOrderItem}
                                        error={formErrors.weight}
                                        InputProps={{ readOnly: true }}
                                    />
                                </Grid>

                                {progressItem && progressItem.articles && progressItem.articles.map((row, i) => (
                                    row.serialNumberMandatory ?
                                        <Grid item xs={12}>
                                            <TextField
                                                margin="dense"
                                                name="serial_number"
                                                id={"articleId_" + row.id + "_" + i}
                                                label={"Serial Number: " + row.label}
                                                type="text"
                                                fullWidth
                                                required
                                                variant="outlined"
                                                color="success"
                                                autoComplete='off'
                                                onChange={this.handleCheckSerialNumber}
                                                error={serialNumberErrors[i]}
                                            //onChange={this.onChangeShipmentNumber}
                                            />
                                        </Grid>
                                        :
                                        <Grid item xs={4}>
                                            <Chip
                                                margin="dense"
                                                name="serial_number"
                                                id={"articleId_" + row.id + "_" + i}
                                                label={row.label}
                                                type="text"
                                                fullWidth
                                                variant="outlined"
                                                icon={<ExtensionIcon />}
                                                color="secondary"
                                                style={{ height: '100px' }}
                                                inputProps={{ readOnly: true }}
                                            />
                                        </Grid>
                                ))}
                            </Grid>
                        </DialogContent>
                        <DialogActions>
                            <Button onClick={this.handleProgressClose}>Cancel</Button>
                            <Button onClick={this.onProgressSaveClicked}>Save</Button>
                        </DialogActions>
                    </Dialog>

                    {/*info dialog over whole order*/}
                    <Dialog
                        maxWidth={"900px"}
                        open={openOrderDetailsDialog}
                        onClose={this.handleDetailsClose}
                        TransitionComponent={Transition}
                    >
                        <AppBar sx={{ position: 'relative' }} style={{ background: '#940234' }} >
                            <Toolbar>
                                <IconButton
                                    edge="start"
                                    color="inherit"
                                    onClick={this.handleDetailsClose}
                                    aria-label="close"
                                >
                                    <CloseIcon />
                                </IconButton>
                                <Typography sx={{ ml: 2, flex: 1 }} variant="h6" component="div">
                                    Details of Order {selectedOrder.id}
                                </Typography>
                            </Toolbar>
                        </AppBar>
                        <Grid container spacing={1} xs={12}>
                            <Grid item xs={3}>
                                <Box item
                                    display="flex"
                                    alignItems="center"
                                    m={3}
                                    p={1}
                                    sx={{ border: '2px solid lightgrey' }}
                                >
                                    {selectedOrder.customer_name} <br></br>
                                    {selectedOrder.customer_phone} <br></br>
                                    {selectedOrder.customer_address} <br></br>
                                    {selectedOrder.customer_country + " " + selectedOrder.customer_zipcode + " " + selectedOrder.customer_city}
                                </Box>

                                <Box item
                                    display="flex"
                                    alignItems="center"
                                    m={3}
                                    p={1}
                                    sx={{ border: '2px dashed lightgrey' }}
                                >
                                    <ArrowDownIcon
                                        fontSize='large'>
                                    </ArrowDownIcon>
                                    <big>Warehouse: <strong>{selectedOrder.acronym}</strong></big>
                                    <ArrowDownIcon
                                        fontSize='large'>
                                    </ArrowDownIcon>
                                </Box>

                                <Box item
                                    display="flex"
                                    //alignItems="center"
                                    m={3}
                                    p={1}
                                    sx={{ border: '2px solid lightgrey' }}
                                >
                                    {selectedOrder.recipient_company} <br></br>
                                    {selectedOrder.recipient_name} <br></br>
                                    {selectedOrder.recipient_phone} <br></br>
                                    {selectedOrder.recipient_address} <br></br>
                                    {selectedOrder.recipient_country + " " + selectedOrder.recipient_zipcode + " " + selectedOrder.recipient_city}
                                </Box>
                            </Grid>

                            <Grid item xs={3}>
                                <List
                                    m={3}
                                    p={1}
                                    width={"80%"}
                                >
                                    <ListItem >
                                        <ListItemText primary="Additional Info" secondary={selectedOrder.additional_infos} />
                                    </ListItem>
                                    <Divider />
                                    <ListItem >
                                        <ListItemText primary="Customer Reference" secondary={selectedOrder.customer_reference} />
                                    </ListItem>
                                    <Divider />
                                    <ListItem >
                                        <ListItemText primary="Status" secondary={selectedOrder.label} />
                                    </ListItem>
                                    <Divider />
                                </List>
                            </Grid>

                            <Grid item xs={6}>
                                <Box item
                                    display="flex"
                                    //alignItems="center"
                                    m={3}
                                    p={1}
                                    sx={{ border: '0px solid lightgrey' }}
                                >
                                    <iframe src={pfdFile} width={"100%"} height={"350px"}></iframe>
                                </Box>
                            </Grid>

                            <Grid item xs={12}>
                                <TableContainer component={Paper}>
                                    <Table stickyHeader className={classes.table} aria-label="Article" width={750}>
                                        <TableHead>
                                            <TableRow>
                                                <TableCell>Stock-ID</TableCell>
                                                <TableCell>Article-ID</TableCell>
                                                <TableCell>Name</TableCell>
                                                <TableCell>Box</TableCell>
                                                <TableCell>Amount</TableCell>
                                                <TableCell>Serialnumber</TableCell>
                                                <TableCell>Storageplace</TableCell>
                                            </TableRow>
                                        </TableHead>
                                        <TableBody>
                                            {selectedOrder && selectedOrder.articles ? selectedOrder.articles.map((row) => (
                                                <TableRow key={row.id}>
                                                    <TableCell >{row.stock_id}</TableCell>
                                                    <TableCell component="th" scope="row">{row.article_id}</TableCell>
                                                    <TableCell >{row.label}</TableCell>
                                                    <TableCell >{row.box}</TableCell>
                                                    <TableCell >{row.count}</TableCell>
                                                    <TableCell >{row.serialnumber}</TableCell>
                                                    <TableCell >{row.storage_place}</TableCell>
                                                </TableRow>
                                            )) : ""}
                                        </TableBody>
                                    </Table>
                                </TableContainer>
                            </Grid>

                        </Grid>
                    </Dialog>
                </div >
        }
        else {
            content =
                <div>
                    <Alert severity="info">This list is empty!</Alert>
                </div>
        }

        return (
            <div className={classes.root}>
                {loadingIndicator}
                {submitInfo}
                {content}
            </div>
        );
    }
}

export default withStyles(useStyles)(OrderHistoryStation)