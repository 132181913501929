import { withStyles } from '@mui/styles';
import React from 'react';

import clsx from 'clsx';
import axios from 'axios';

import CssBaseline from '@mui/material/CssBaseline';
import Drawer from '@mui/material/Drawer';
import AppBar from '@mui/material/AppBar';
import Toolbar from '@mui/material/Toolbar';
import List from '@mui/material/List';
import Typography from '@mui/material/Typography';
import Divider from '@mui/material/Divider';
import IconButton from '@mui/material/IconButton';
import Container from '@mui/material/Container';
import MenuIcon from '@mui/icons-material/Menu';

import ChevronLeftIcon from '@mui/icons-material/ChevronLeft';
import ChevronRightIcon from '@mui/icons-material/ChevronRight';
import AddBusinessIcon from '@mui/icons-material/AddBusiness';
import ListItem from '@mui/material/ListItem';
import ListItemIcon from '@mui/material/ListItemIcon';
import ListItemText from '@mui/material/ListItemText';
import PeopleIcon from '@mui/icons-material/People';
import TaskIcon from '@mui/icons-material/Task';
import LayersIcon from '@mui/icons-material/Layers';
import ExitToAppIcon from '@mui/icons-material/ExitToApp';
import ArticleIcon from '@mui/icons-material/Article';
import InventoryIcon from '@mui/icons-material/Inventory';
import HistoryIcon from '@mui/icons-material/History';
import TimelineIcon from '@mui/icons-material/Timeline';
import ApartmentIcon from '@mui/icons-material/Apartment';
import ApprovalIcon from '@mui/icons-material/Approval';
import AccountBalanceIcon from '@mui/icons-material/AccountBalance';
import HelpIcon from '@mui/icons-material/Help';

import Users from './Users/Users';
import Stations from './Stations/Stations';
import Articles from './Articles/Articles';
import Upload from './Upload/Upload';
import Order from "./Order/Order";
import StockItems from './StockItems/StockItems';
import StockOverview from './StockOverview/StockOverview';
import Reload from "./Reload/Reload";
import OrderHistoryCustomer from "./Order/OrderHistoryCustomer";
import OrderHistoryStation from "./Order/OrderHistoryStation";
import Customers from './Customers/Customers';
import Disclaimer from './Disclaimer/Disclaimer';
import Usermanual from './Usermanual/Usermanual';

import Tooltip from '@mui/material/Tooltip';

import { Box } from '@mui/material';

const drawerWidth = 240;

const useStyles = (theme) => ({
  root: {
    display: 'flex',
    paddingLeft: 75,
    paddingRight: 25,
    paddingTop: 0,
  },
  toolbar: {
    paddingRight: 24, // keep right padding when drawer closed
  },
  toolbarIcon: {
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'flex-end',
    padding: '0 8px',
    ...theme.mixins.toolbar,
  },
  appBar: {
    zIndex: theme.zIndex.drawer + 1,
    "background-color": "#940234",
    transition: theme.transitions.create(['width', 'margin'], {
      easing: theme.transitions.easing.sharp,
      duration: theme.transitions.duration.leavingScreen,
    }),
  },
  appBarShift: {
    marginLeft: drawerWidth,
    width: `calc(100% - ${drawerWidth}px)`,
    transition: theme.transitions.create(['width', 'margin'], {
      easing: theme.transitions.easing.sharp,
      duration: theme.transitions.duration.enteringScreen,
    }),
  },
  menuButton: {
    marginRight: 36,
  },
  menuButtonHidden: {
    display: 'none',
  },
  title: {
    flexGrow: 1,
    paddingLeft: 200,
  },
  drawerPaper: {
    position: 'relative',
    whiteSpace: 'nowrap',
    width: drawerWidth,
    transition: theme.transitions.create('width', {
      easing: theme.transitions.easing.sharp,
      duration: theme.transitions.duration.enteringScreen,
    }),
  },
  drawerPaperClose: {
    overflowX: 'hidden',
    transition: theme.transitions.create('width', {
      easing: theme.transitions.easing.sharp,
      duration: theme.transitions.duration.leavingScreen,
    }),
    width: theme.spacing(7),
    [theme.breakpoints.up('sm')]: {
      width: theme.spacing(9),
    },
  },
  appBarSpacer: theme.mixins.toolbar,
  content: {
    flexGrow: 1,
    height: '100vh',
    overflow: 'auto',
  },
  container: {
    paddingTop: theme.spacing(4),
    paddingBottom: theme.spacing(4),
  },
  paper: {
    padding: theme.spacing(2),
    display: 'flex',
    overflow: 'auto',
    flexDirection: 'column',
  },
  form: {
    color: "white",
    "border-color": "white",
  },
  formInput: {
    color: "white !important"
  },
  fixedHeight: {
    height: 240,
  },
});

class Private extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      open: false,
      setOpen: "",
      users: [],
      stations: [],
      sationId: 0,
      secondary: "",
      selected: "stations",
      setSecondary: "",
      pageTitle: "Stations",
      page: <Stations />,
      currentUser: JSON.parse(localStorage.getItem("user"))
    };
  }

  componentDidMount() {
    const { currentUser } = this.state;


    this.setState({
      stationId: this.state.currentUser.stationId
    })

    switch (currentUser.role) {
      case "Customer":
        this.setState({
          page: <OrderHistoryCustomer handleOrderEdit={this.handleOrderEdit} />,
          pageTitle: "Orderhistory Customer",
          selected: "orderhistorycustomer",
        })
        break;
      case "Station":
        this.setState({
          page: <OrderHistoryStation />,
          pageTitle: "Orderhistory Station",
          selected: "orderhistorystation",
        })
        break;
    }

    if (window.location.pathname.indexOf("order") > -1) {
      const urlLocation = window.location.pathname.split("/");
      let id = null;
      if (urlLocation.length > 1 && urlLocation[2]) {
        id = urlLocation[2];
      }
      this.setState({
        page: <OrderHistoryStation showDetails={id} />,
        pageTitle: "Orderhistory Station",
        selected: "orderhistorystation",
      })
    }

  }

  getStations() {
    axios.get(`${process.env.REACT_APP_API_URL}/stations`, {
      auth: {
        username: localStorage.getItem('username') || "",
        password: localStorage.getItem('password') || ""
      }
    }).then((response) => {
      //console.log(response);
      this.setState({
        stations: response.data.result
      })
    }).catch((err) => {
      console.log(err);
    });
  }

  handleDrawerToggle = () => {
    const { open } = this.state;
    this.setState({
      open: !open
    });
    return;
  };

  handleOrderEdit = (orderItem) => {
    this.setState({
      page: <Order editItem={orderItem} />,
      pageTitle: "Change Order",
      selected: "order",
    })
  }

  handlePageSwitch = (e, target) => {
    //console.log(target)
    switch (target) {
      case "upload":
        this.setState({
          page: <Upload />,
          pageTitle: "Upload",
          selected: "upload"
        })
        break;
      case "users":
        this.setState({
          page: <Users />,
          pageTitle: "Users",
          selected: "users",
        })
        break;
      case "stations":
        this.setState({
          page: <Stations />,
          pageTitle: "Stations",
          selected: "stations",
        })
        break;
      case "order":
        this.setState({
          page: <Order editItem={null} />,
          pageTitle: "Order",
          selected: "order",
        })
        break;
      case "articles":
        this.setState({
          page: <Articles />,
          pageTitle: "Articles",
          selected: "articles",
        })
        break;
      case "stockItems":
        this.setState({
          page: <StockItems />,
          pageTitle: "Stock",
          selected: "stockItems",
        })
        break;
      case "stockOverview":
        this.setState({
          page: <StockOverview />,
          pageTitle: "Stock-Overview",
          selected: "stockOverview",
        })
        break;
      case "usermanual":
        this.setState({
          page: <Usermanual />,
          pageTitle: "User Manual",
          selected: "usermanual",
        })
        break;
      case "orderHistoryCustomer":
        this.setState({
          page: <OrderHistoryCustomer handleOrderEdit={this.handleOrderEdit} />,
          pageTitle: "Orderhistory Customer",
          selected: "orderhistorycustomer",
        })
        break;
      case "orderHistoryStation":
        this.setState({
          page: <OrderHistoryStation />,
          pageTitle: "Orderhistory Station",
          selected: "orderhistorystation",
        })
        break;
      case "customers":
        this.setState({
          page: <Customers />,
          pageTitle: "Customers",
          selected: "customers",
        })
        break;
      case "disclaimer":
        this.setState({
          page: <Disclaimer />,
          pageTitle: "Disclaimer",
          selected: "disclaimer",
        })
        break;
    }
  }

  handleSwitchStation = (e) => {
    const { currentUser } = this.state;

    const update = {
      stationId: e.target.value,
      id: currentUser.id
    }

    axios({
      method: "PUT",
      url: `${process.env.REACT_APP_API_URL}/user`,
      data: update,
      auth: {
        username: localStorage.getItem('username') || "",
        password: localStorage.getItem('password') || ""
      }
    })
      .then((response) => {
        //console.log(response.data);
        const { page, pageTitle } = this.state;
        this.setState({
          stationId: e.target.value,
          page: <Reload />,
          pageTitle: "Reloading"
        })
        setTimeout(() => {
          this.setState({
            page: page,
            pageTitle: pageTitle
          })
        }, 500)

      })
      .catch(function (error) {
        console.log(error);
      });
  }

  handleLogout = (e) => {
    console.log(this.props);
    const { handleLogout } = this.props;
    return handleLogout(e)
  }

  renderMenuItemsPerUserRole() {
    const { currentUser, selected } = this.state;

    let menu = "";
    switch (currentUser.role) {
      case "Admin":
        menu =
          <List>
            <Tooltip title="Stations" placement="right">
              <ListItem button onClick={((e) => this.handlePageSwitch(e, "stations"))} selected={selected === "stations"}>
                <ListItemIcon>
                  <AddBusinessIcon />
                </ListItemIcon>
                <ListItemText primary="Stations" />
              </ListItem>
            </Tooltip>
            <Tooltip title="Users" placement="right">
              <ListItem button onClick={((e) => this.handlePageSwitch(e, "users"))} selected={selected === "users"}>
                <ListItemIcon>
                  <PeopleIcon />
                </ListItemIcon>
                <ListItemText primary="Users" />
              </ListItem>
            </Tooltip>
            <Tooltip title="Customers" placement="right">
              <ListItem button onClick={((e) => this.handlePageSwitch(e, "customers"))} selected={selected === "customers"}>
                <ListItemIcon>
                  <ApartmentIcon />
                </ListItemIcon>
                <ListItemText primary="Customers" />
              </ListItem>
            </Tooltip>
            <Tooltip title="Order" placement="right">
              <ListItem button onClick={((e) => this.handlePageSwitch(e, "order"))} selected={selected === "order"}>
                <ListItemIcon>
                  <TaskIcon />
                </ListItemIcon>
                <ListItemText primary="Order" />
              </ListItem>
            </Tooltip>
            <Tooltip title="Orderhistory Customer" placement="right">
              <ListItem button onClick={((e) => this.handlePageSwitch(e, "orderHistoryCustomer"))} selected={selected === "orderHistoryCustomer"}>
                <ListItemIcon>
                  <HistoryIcon />
                </ListItemIcon>
                <ListItemText primary="Orderhistory Customer" />
              </ListItem>
            </Tooltip>
            <Tooltip title="Orderhistory Station" placement="right">
              <ListItem button onClick={((e) => this.handlePageSwitch(e, "orderHistoryStation"))} selected={selected === "orderHistoryStation"}>
                <ListItemIcon>
                  <TimelineIcon />
                </ListItemIcon>
                <ListItemText primary="Orderhistory Station" />
              </ListItem>
            </Tooltip>
            <Tooltip title="Articles" placement="right">
              <ListItem button onClick={((e) => this.handlePageSwitch(e, "articles"))} selected={selected === "article"}>
                <ListItemIcon>
                  <ArticleIcon />
                </ListItemIcon>
                <ListItemText primary="Articles" />
              </ListItem>
            </Tooltip>
            <Tooltip title="StockItems" placement="right">
              <ListItem button onClick={((e) => this.handlePageSwitch(e, "stockItems"))} selected={selected === "stockItems"}>
                <ListItemIcon>
                  <InventoryIcon />
                </ListItemIcon>
                <ListItemText primary="StockItems" />
              </ListItem>
            </Tooltip>
            <Tooltip title="Stock-Overview" placement="right">
              <ListItem button onClick={((e) => this.handlePageSwitch(e, "stockOverview"))} selected={selected === "stockOverview"}>
                <ListItemIcon>
                  <AccountBalanceIcon />
                </ListItemIcon>
                <ListItemText primary="Stock-Overview" />
              </ListItem>
            </Tooltip>
            <Tooltip title="Upload" placement="right">
              <ListItem button onClick={((e) => this.handlePageSwitch(e, "upload"))} selected={selected === "upload"}>
                <ListItemIcon>
                  <LayersIcon />
                </ListItemIcon>
                <ListItemText primary="Upload" />
              </ListItem>
            </Tooltip>
            <Tooltip title="User Manual" placement="right">
              <ListItem button onClick={((e) => this.handlePageSwitch(e, "usermanual"))} selected={selected === "usermanual"}>
                <ListItemIcon>
                  <HelpIcon />
                </ListItemIcon>
                <ListItemText primary="User Manual" />
              </ListItem>
            </Tooltip>
            <Tooltip title="Disclaimer" placement="right">
              <ListItem button onClick={((e) => this.handlePageSwitch(e, "disclaimer"))} selected={selected === "disclaimer"}>
                <ListItemIcon>
                  <ApprovalIcon />
                </ListItemIcon>
                <ListItemText primary="Disclaimer" />
              </ListItem>
            </Tooltip>
            <Tooltip title="Logout" placement="right">
              <ListItem button onClick={this.handleLogout}>
                <ListItemIcon>
                  <ExitToAppIcon />
                </ListItemIcon>
                <ListItemText primary="Logout" />
              </ListItem>
            </Tooltip>
          </List>
        break;
      case "Customer":
        menu =
          <List>
            <ListItem button onClick={((e) => this.handlePageSwitch(e, "order"))} selected={selected === "order"}>
              <ListItemIcon>
                <TaskIcon />
              </ListItemIcon>
              <ListItemText primary="Order" />
            </ListItem>
            <ListItem button onClick={((e) => this.handlePageSwitch(e, "orderHistoryCustomer"))} selected={selected === "orderHistoryCustomer"}>
              <ListItemIcon>
                <HistoryIcon />
              </ListItemIcon>
              <ListItemText primary="Orderhistory" />
            </ListItem>
            <ListItem button onClick={((e) => this.handlePageSwitch(e, "stockOverview"))} selected={selected === "stockOverview"}>
              <ListItemIcon>
                <AccountBalanceIcon />
              </ListItemIcon>
              <ListItemText primary="StockOverview" />
            </ListItem>
            <Tooltip title="User Manual" placement="right">
              <ListItem button onClick={((e) => this.handlePageSwitch(e, "usermanual"))} selected={selected === "usermanual"}>
                <ListItemIcon>
                  <HelpIcon />
                </ListItemIcon>
                <ListItemText primary="User Manual" />
              </ListItem>
            </Tooltip>
            <ListItem button onClick={((e) => this.handlePageSwitch(e, "disclaimer"))} selected={selected === "disclaimer"}>
              <ListItemIcon>
                <ApprovalIcon />
              </ListItemIcon>
              <ListItemText primary="Disclaimer" />
            </ListItem>
            <ListItem button onClick={this.handleLogout}>
              <ListItemIcon>
                <ExitToAppIcon />
              </ListItemIcon>
              <ListItemText primary="Logout" />
            </ListItem>
          </List>
        break;
      case "Station":
      case "Supervisor":
        menu =
          <List>
            <ListItem button onClick={((e) => this.handlePageSwitch(e, "users"))} selected={selected === "users"}>
              <ListItemIcon>
                <PeopleIcon />
              </ListItemIcon>
              <ListItemText primary="Users" />
            </ListItem>
            <ListItem button onClick={((e) => this.handlePageSwitch(e, "customers"))} selected={selected === "customers"}>
              <ListItemIcon>
                <ApartmentIcon />
              </ListItemIcon>
              <ListItemText primary="Customers" />
            </ListItem>
            <ListItem button onClick={((e) => this.handlePageSwitch(e, "order"))} selected={selected === "order"}>
              <ListItemIcon>
                <TaskIcon />
              </ListItemIcon>
              <ListItemText primary="Order" />
            </ListItem>
            <ListItem button onClick={((e) => this.handlePageSwitch(e, "orderHistoryStation"))} selected={selected === "orderHistoryStation"}>
              <ListItemIcon>
                <TimelineIcon />
              </ListItemIcon>
              <ListItemText primary="Orderhistory Station" />
            </ListItem>
            <ListItem button onClick={((e) => this.handlePageSwitch(e, "articles"))} selected={selected === "article"}>
              <ListItemIcon>
                <ArticleIcon />
              </ListItemIcon>
              <ListItemText primary="Articles" />
            </ListItem>
            <ListItem button onClick={((e) => this.handlePageSwitch(e, "stockItems"))} selected={selected === "stockItems"}>
              <ListItemIcon>
                <InventoryIcon />
              </ListItemIcon>
              <ListItemText primary="StockItems" />
            </ListItem>
            <ListItem button onClick={((e) => this.handlePageSwitch(e, "stockOverview"))} selected={selected === "stockOverview"}>
              <ListItemIcon>
                <AccountBalanceIcon />
              </ListItemIcon>
              <ListItemText primary="StockOverview" />
            </ListItem>
            <Tooltip title="User Manual" placement="right">
              <ListItem button onClick={((e) => this.handlePageSwitch(e, "usermanual"))} selected={selected === "usermanual"}>
                <ListItemIcon>
                  <HelpIcon />
                </ListItemIcon>
                <ListItemText primary="User Manual" />
              </ListItem>
            </Tooltip>
            <ListItem button onClick={((e) => this.handlePageSwitch(e, "disclaimer"))} selected={selected === "disclaimer"}>
              <ListItemIcon>
                <ApprovalIcon />
              </ListItemIcon>
              <ListItemText primary="Disclaimer" />
            </ListItem>
            <ListItem button onClick={this.handleLogout}>
              <ListItemIcon>
                <ExitToAppIcon />
              </ListItemIcon>
              <ListItemText primary="Logout" />
            </ListItem>
          </List>
        break;


      default:
        menu =
          <List>
            <ListItem button onClick={this.handleLogout}>
              <ListItemIcon>
                <ExitToAppIcon />
              </ListItemIcon>
              <ListItemText primary="Logout" />
            </ListItem>
          </List>
        break;
    }

    return menu;
  }

  render() {
    const { classes } = this.props;
    const { open, page, pageTitle, currentUser, selected } = this.state;

    let stationSelect = "";

    return (
      <div className={classes.root}>
        <CssBaseline />
        <AppBar position="absolute" style={{ background: '#940234' }} className={clsx(classes.appBar, open && classes.appBarShift)}>
          <Toolbar className={classes.toolbar}>
            <IconButton
              edge="start"
              color="inherit"
              aria-label="open drawer"
              className={clsx(classes.menuButton, open && classes.menuButtonHidden)}
            >
              <MenuIcon />
            </IconButton>
            <Typography component="h1" variant="h6" color="inherit" noWrap className={classes.title}>
              {pageTitle}
            </Typography>
            <Typography component="h1" variant="h6" color="inherit" noWrap align='right'>
              GO! Express & Logistics Giessen GmbH
            </Typography>

            <Box m={1}>
              <a href="http://www.general-overnight.com">
                <img src="/logo.jpg" alt="GO! Logo" height={"40px"}></img>
              </a>
            </Box>

          </Toolbar>
        </AppBar>
        <Drawer
          variant="permanent"
          classes={{
            paper: clsx(classes.drawerPaper, !open && classes.drawerPaperClose),
          }}
          open={open}
        >
          <div className={classes.toolbarIcon}>
            <IconButton onClick={this.handleDrawerToggle}>
              {open ? <ChevronLeftIcon fontSize='large' /> : <ChevronRightIcon fontSize='large' />}
            </IconButton>
          </div>
          <Divider />
          {this.renderMenuItemsPerUserRole()}
          <Divider />
        </Drawer>
        <main className={classes.content}>
          <div className={classes.appBarSpacer} />
          <Container maxWidth="lg" className={classes.container}>
            {page}
          </Container>
        </main>
      </div>
    );
  }
}

export default withStyles(useStyles)(Private)